import PasswordSent from "./PasswordSent";
import '../../assets/css/signup.css'
import '../../assets/css/forgotpsw.css'
import '../../assets/css/verification.css'

function Index() {

    return (
        <PasswordSent />
    )
}
export default Index