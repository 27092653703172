import styled from "@emotion/styled";
import { Avatar, Button, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TableCell, TextField } from "@mui/material"
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ShareIcon from '@mui/icons-material/Share';
import testingicon from "../../assets/images/icon.png";
import EmailIcon from '@mui/icons-material/Email';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React, { useState,useEffect } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector, useDispatch } from "react-redux";
import {
    setToken,
    setLoading,
    setUserDetails,
} from "../../redux/user/user.action";
import ApiCall from "../../Api/index"
import { validateAll } from "indicative/validator";
import { Messages } from "../../common/msg"
import ToastAlert from "../../common/ToastAlert";
import SendIcon from '@mui/icons-material/Send';
import RedCloseWithCircle from "../../assets/images/red_close_with_circle.png"
import edit_png from "../../assets/images/alldata_edit.png"
import site_manager_edit from "../../assets/images/site_manager.png"


import { useRef } from "react";
import AWS from 'aws-sdk'
import SendTeamModal from "./SendTeamModal";
import { useNavigate, useLocation } from "react-router-dom";

export const StyledReportMainWrapper = styled.div`
  background:#F9F8F8;
  padding-bottom: 6%;
`;
export const StyledContentMain = styled.div`
display:'inline';
float:'left';
`;

export const StyledContentWhiteBlock = styled.div`
background-color:#fff;
padding:2%;
border-radius:8px;
margin-top:1em;
width: 50%;
margin: 1%;
padding-bottom: 7%;
`;
export const StyledContentWhiteBlockRight = styled.div`
background-color:#fff;
padding:2%;
border-radius:8px;
margin-top:1em;
width: 100%;
float: right;
padding-bottom: 2%;
height: 0%;
`;
export const StyledContentWhiteBlockRightSecond = styled.div`
background-color:#fff;
padding:2%;
border-radius:8px;
margin-top:1em;
width: 100%;
margin: 3%;
float: right;
padding-bottom: 2%;
height: 0%;
display: flex;
flex-direction: column;
`;

export const StyledContentHeader = styled.div`
display:inline;
font-size: large;
`;
export const StyledContentHeaderRight = styled.div`
display:flex;
font-size: large;
gap: 7%;
`;
export const StyledContentHeaderRightTow = styled.div`
display:flex;
font-size: large; 
margin-top: 3%;
display: flex;
flex-direction: row;
justify-content: space-between;
`;
export const StyledContentHeaders = styled.div`
display:flex;
float:right;
gap:5%;
`;
export const StyledContentHeadersRight = styled.div`
display:inline;
float:left;
`;
export const StyledContentImage = styled.div`

`;



function Setting({ buildingName, buildingImage, buildingEmail, buildingManager, filterProprtyManagers, search, onClickAddUser, formBuildingData, setFormBuildingData, id, buildingCity, buildingState, buildingStreetNumber, buildingZip, buildingStreetName, buildingPhone, setBuildingImage, setBuildingName, setBuildingEmail, setBuildingStreetNumber, setBuildingStreetName, setBuildingCity, setBuildingState, setBuildingZip, setBuildingPhone, isUserSaved, setIsUserSaved,filterFloorRepresentative,floorRepresentative,searchFloor }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showAction, setShowAction] = useState('');
    const [isEdit, setIsEdit] = useState(false)
    const [managerModalShow, setManagerModalShow] = useState(false);
    const token = useSelector((state) => state.user.userToken);
    const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const [formData, setFormData] = useState({
        input_type : true
    });


    const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
    const REGION = process.env.REACT_APP_REGION;
    const MAX_FILE_SIZE_BYTES = 2 * 1024 * 1024;

    AWS.config.update({
        accessKeyId: process.env.REACT_APP_ACCESS_ID,
        secretAccessKey: process.env.REACT_APP_ACCESS_KEY
    })

    const myBucket = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
    })


    const [progress, setProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [latestFileUploadName, setLatestFileUploadName] = useState('')
    const [tpPassword, setTempPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [emailType, setEmailType] = useState("");
    const userOptions = useRef(null)
    const [imagePreview, setImagePreview] = useState(null);

    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            function handleClickOutside(event) {
                console.log("target",event.target)
                if (ref.current && !ref.current.contains(event.target)) {

                    if (event?.target?.id !== 'usersOptions') {
                        setShowAction(false)
                    }
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideAlerter(userOptions);
    

    const setPassword = (e) => {
        setTempPassword(e.target.value)
    }

    const handleFileInput = (e) => {
        //setSelectedFile(e.target.files[0]);
        const file = e.target.files[0];
        console.log('file', file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setImagePreview(null);
        }
        console.log('file name', e.target.files[0])
        setSelectedFile(e.target.files[0]);
        console.log('setSelectedFile', setSelectedFile);
    }
 
    const makeid = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    const uploadFile = (file) => {

        setErrorMessage("");
        let newFileName = selectedFile?.name;

        if (newFileName != null && newFileName != undefined) {
            dispatch(setLoading(true))
            let fileExtension = newFileName?.substr(newFileName.lastIndexOf('.') + 1);
            if (!['jpg', 'jpeg', 'png'].includes(fileExtension)) {
                console.log('Select a valid image file (jpg, jpeg, or png).');
                setErrorMessage("Select a valid image file (jpg, jpeg, or png).");
                return false;
            }

            if (file?.size > MAX_FILE_SIZE_BYTES) {
                setErrorMessage("File size exceeds the maximum limit of 2MB .");
                console.log('File size exceeds the maximum limit of 2MB.');
                return false;
            }

            let uniqueFileName = makeid(10) + '.' + fileExtension;

            const params = {
                ACL: 'public-read',
                Body: file,
                Bucket: S3_BUCKET + '/' + process.env.REACT_APP_DIR_NAME,
                Key: uniqueFileName
            };

            myBucket.putObject(params)
                .on('httpUploadProgress', (evt) => {
                    setProgress(Math.round((evt.loaded / evt.total) * 100))
                    setLatestFileUploadName(uniqueFileName)
                    setOpen(false);
                    dispatch(setLoading(false))
                })
                .send((err) => {
                    if (err) console.log("file error", err)
                })
        } else {
            setErrorMessage("Please upload file");
        }

    }


    const editBuilding = () => {
        setIsEdit(!isEdit)
    }

    // Delete Handle
    const [Deleteopen, setDeleteOpen] = React.useState(false);

    const handleDelete = (id) => {
        setUserID(id);
        setDeleteOpen(true);
    }
    const handleConfirmDelete = async () => {
        const data = {
            "id": userID,
            "status": 0,
        }
        dispatch(setLoading(true))
        const UserDelete = await ApiCall('v1/manage-user/update-status', 'POST', data, token);
        if (UserDelete.CODE == '406') {
            dispatch(setLoading(false))
            ToastAlert({ title: "User Delete", msg: UserDelete?.MESSAGE, msgType: "error" })
        } else {
            dispatch(setLoading(false))
            setDeleteOpen(false);
            setIsUserSaved(!isUserSaved);
            ToastAlert({ title: "User Delete", msg: "User Delete Successfully", msgType: "success" })
        }
    };

    const handleCancelDelete = () => {
        setDeleteOpen(false);
    };

    const handleForm = (e) => {
        setFormBuildingData({
            ...formBuildingData,
            [e.target.id]: e.target.value,
        });
    };

    const saveBuilding = async () => {

        const rules = {
            building_email: "required|email",
            building_name: "required",
            building_streetNumber: "required",
            building_streetName: "required",
            building_city: "required",
            building_state: "required",
            building_zip: "required",
        };

        const message = {
            "building_name.required": "Name " + Messages.IS_REQUIRED,
            "building_email.required": "Email " + Messages.IS_REQUIRED,
            "building_email.email": "Email " + Messages.IS_INVALID,
            "building_streetNumber.required": "Street Number " + Messages.IS_REQUIRED,
            "building_streetName.required": "Street Name " + Messages.IS_REQUIRED,
            "building_city.required": "City " + Messages.IS_REQUIRED,
            "building_state.required": "State " + Messages.IS_REQUIRED,
            "building_zip.required": "Zip " + Messages.IS_REQUIRED,
        };

        validateAll(formBuildingData, rules, message) 
            .then(async () => {
                setFormBuildingData({
                    ...formBuildingData,
                    error: {},
                });

                let buildingUploadedImage = process.env.REACT_APP_BUILDING_IMAGE_URL + latestFileUploadName
                if (latestFileUploadName == '' || latestFileUploadName == null) {
                    buildingUploadedImage = buildingImage
                }
                const data = {
                    "full_address": formBuildingData.building_streetNumber + ' ' + formBuildingData.building_streetName + ', ' + formBuildingData.building_city + ', ' + formBuildingData.building_state + ', ' + formBuildingData.building_zip,
                    "name": formBuildingData.building_name,
                    "email": formBuildingData.building_email,
                    "street_number": formBuildingData.building_streetNumber,
                    "street_name": formBuildingData.building_streetName,
                    "city": formBuildingData.building_city,
                    "state": formBuildingData.building_state,
                    "zip": formBuildingData.building_zip.toString(),
                    "phone": formBuildingData.building_phone,
                    "building_image": buildingUploadedImage
                    //"emailNotifications":"received,delivered,pickedup"
                }
                dispatch(setLoading(true))
                const buildingList = await ApiCall('v1/manage-building/' + id, 'PUT', data, token);
                console.log("update building", buildingList.error)
                if (buildingList.error) {
                    dispatch(setLoading(false))
                    ToastAlert({ title: "Update Building", msg: buildingList?.error?.response?.data?.MESSAGE, msgType: "error" })
                    setFormBuildingData({
                        ...formBuildingData,
                        error: '',
                    });
                } else {
                    setIsEdit(!isEdit)
                    dispatch(setLoading(false))
                    setBuildingImage(buildingUploadedImage)
                    setBuildingName(formBuildingData.building_name)
                    setBuildingEmail(formBuildingData.building_email)
                    setBuildingStreetNumber(formBuildingData.building_streetNumber)
                    setBuildingStreetName(formBuildingData.building_streetName)
                    setBuildingCity(formBuildingData.building_city)
                    setBuildingState(formBuildingData.building_state)
                    setBuildingZip(formBuildingData.building_zip)
                    setBuildingPhone(formBuildingData.building_phone)
                    ToastAlert({ title: "Update Builiding", msg: "Building updated successfully", msgType: "success" })
                    const selectedBuildingUpdate = document.querySelector('.selectedBuilding');
                    if (selectedBuildingUpdate) {
                        selectedBuildingUpdate.textContent = formBuildingData.building_name;
                    }
                    const dropdownBuildingUpdate = document.querySelector('#building-'+id);
                    if (dropdownBuildingUpdate) {
                        dropdownBuildingUpdate.textContent = formBuildingData.building_name;
                    } 
                }
            })
            .catch((errors) => {
                const formaerrror = {};
                if (errors.length) {
                    errors.forEach(function (value) {
                        formaerrror[value.field] = value.message;
                    });
                }
                console.log("form error", formaerrror)
                setFormBuildingData({
                    ...formBuildingData,
                    error: formaerrror,
                });
                ToastAlert({ title: "Update Builiding", msg: "Something went wrong", msgType: "error" })
            });
    };

    const closeEdit = () => {
        setIsEdit(!isEdit)
    }

    const [open, setOpen] = React.useState(false);
    const [openSetPasswordOpen, setPasswordOpen] = React.useState(false);
    const [userID, setUserID] = useState('');
    const [tempPasswordTitle,setTempPasswordTitle] = useState('');

    const handleClickOpen = () => {
        setImagePreview('')
        setOpen(true);
    };

    const handleClickPasswordOpen = (id,isEmail,isFloor) => {
        setPasswordOpen(true);
        setUserID(id)
        setTempPassword('')
        setPasswordError(false)
        setEmailType(isEmail)
        if(isEmail == '1') {
            if(isFloor == 1) {
                setTempPasswordTitle('Reset Password');
            } else {
                setTempPasswordTitle('Send Temp Password');
            }
        } else {
            setTempPasswordTitle('Send Temp Password To Building');
        }
    };
    const handleClickPasswordClose = () => {
        setPasswordOpen(false);
    };
    const handleClose = () => {
        setErrorMessage("")
        setOpen(false);
    };

    const handleClickPasswordSubmit = async () => {
        if (tpPassword == '') {
            setPasswordError("Please enter password")
            return false
        } else if (tpPassword.length<8) {
            setPasswordError("Password length must be greater or equal to 8")
            return false
        }
        const data = {
            "id": userID,
            "password": tpPassword,
            "type" : emailType    
        }
        dispatch(setLoading(true))
        const tempPassword = await ApiCall('v1/send-temp-password-web', 'POST', data, token);
        if (tempPassword.CODE == '406') {
            dispatch(setLoading(false))
            ToastAlert({ title: "Temporary Password", msg: tempPassword?.MESSAGE, msgType: "error" })
        } else {
            dispatch(setLoading(false))
            setPasswordOpen(false);
            ToastAlert({ title: "Temporary Password", msg: "Temporary password sent successfully", msgType: "success" })
        }
    }
    function BootstrapDialogTitle(props) {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <img src={RedCloseWithCircle} alt="" onClick={onClose} style={{ position: 'absolute', right: 8, top: 10, width: '40px', height: '40px', cursor: "pointer" }} />
                ) : null}
            </DialogTitle>
        );
    }


    return (
        <>
            {console.log('buildingManager', buildingManager)}
            <StyledReportMainWrapper className="settingBlock">
                <StyledContentMain className="settingContainer">
                    <div className="settingRow">
                        <div className="settingCol50">
                            <StyledContentWhiteBlock className="settingLeftBlock">
                                <StyledContentHeader>
                                    <div className="settingLeftHeaderBlock">
                                        <h3 className="buildingTitle">{buildingName}</h3>
                                        <StyledContentHeaders className="settingLeftHeadActions">
                                            {
                                                isEdit ?
                                                    <>
                                                        {/* <div style={{ paddingLeft: '6%' }}> */}
                                                        <Button variant="contained" className="sbnBtn sbnBtnSecondary" onClick={closeEdit} ><span>Cancel</span></Button>
                                                        <Button variant="contained" className="sbnBtn sbnBtnPrimary" onClick={saveBuilding}><span>Save</span></Button>
                                                        {/* </div> */}
                                                    </>
                                                    :
                                                    <div class="settingLeftHeadEditBtn">
                                                        {/* <edit_png style={{ color: '#856ECE' }} onClick={editBuilding} /> */}
                                                        <img style={{ width: '50px', height: '50px' }} src={edit_png} onClick={editBuilding} />
                                                        {/* <ShareIcon style={{ paddingLeft: '6%', color: '#BC1E2D' }} /> */}
                                                    </div>
                                            }
                                        </StyledContentHeaders>
                                    </div>
                                    <StyledContentImage className="settingBuildingImgBlock">
                                        {
                                            isEdit ?
                                                <>
                                                    {/* <div style={{ position: 'absolute', left: '40%', top: '184px' }}>
                                                        <Button variant="contained" style={{ borderRadius: '20px', color: '#88352D', backgroundColor: '#FFFFFF' }} >
                                                            <span><DeleteIcon /></span>
                                                        </Button>
                                                    </div> */}
                                                    <div className="settingBuildingImgEditBtnBlock">
                                                        {/* <Button variant="contained" className="settingBuildingImgBtn settingDeleteBtn"><span><DeleteIcon /></span></Button> */}
                                                        <Button variant="contained" className="settingBuildingImgBtn settingEditBtn" onClick={handleClickOpen} ><span><DriveFileRenameOutlineIcon /></span></Button>
                                                        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                                                            <DialogTitle id="alert-dialog-title">Upload Building Image</DialogTitle>
                                                            <DialogContent>
                                                                <DialogContentText id="alert-dialog-description">
                                                                {errorMessage && <p id="email_err" className="error-msg" style={{ color: 'red',marginTop : '30px' }}>{errorMessage}</p>}
                                                                    {/* <input type="file" id="bulding_img" name="bulding_img" accept=".jpg, .jpeg, .png" onChange={handleFileInput} /> */}
                                                                    <label htmlFor="bulding_img" className="form-label" style={{ background: "#ffffff",marginTop : '40px' }} >
                                                                        <input type="file" id="bulding_img" name="bulding_img" className="form-input" onChange={handleFileInput} style={{ border: '1.5px solid #e3e3e3', display: 'none' }} accept="image/*" />
                                                                        <Button     
                                                                            className="btn-choose"
                                                                            variant="outlined"
                                                                            component="span" >
                                                                            Choose Image
                                                                        </Button>
                                                                        {
                                                                            imagePreview ?
                                                                                (
                                                                                    <img src={imagePreview} alt="Preview" style={{ maxWidth: '20%', borderRadius: '5%', paddingLeft: '5%' }} />
                                                                                )
                                                                                :
                                                                                ''
                                                                        }
                                                                    </label>
                                                                </DialogContentText>
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button onClick={handleClose}>Close</Button>
                                                                <Button onClick={() => uploadFile(selectedFile)} autoFocus>Submit</Button>
                                                            </DialogActions>
                                                        </Dialog>
                                                    </div>
                                                    <img style={{ width: '100%', height: '500px', padding: '3%', borderRadius: '40px' }} src={buildingImage} />
                                                </>
                                                :
                                                <img style={{ width: '100%', height: '500px', padding: '3%', borderRadius: '40px' }} src={buildingImage} />
                                        }
                                    </StyledContentImage>
                                    {
                                        isEdit ?
                                            <>
                                                <div className="settingBuildingFormRow">
                                                    {/* Name */}
                                                    <div className="settingBuildingFormCol">
                                                        <TextField fullWidth inputProps={{ maxLength: 50 }} className="inputField" label="Name" id="building_name" value={formBuildingData.building_name} onChange={handleForm} /><br />
                                                        {formBuildingData.error && formBuildingData.error.building_name && <span className="error-msg" style={{ bottom: 'unset' }}>{formBuildingData.error.building_name}</span>}
                                                    </div>
                                                    {/* Street Number */}
                                                    <div className="settingBuildingFormCol">
                                                        <TextField fullWidth className="inputField" label="Street Number" id="building_streetNumber"
                                                            value={formBuildingData.building_streetNumber} onChange={handleForm}>
                                                        </TextField><br />
                                                        {formBuildingData.error && formBuildingData.error.building_streetNumber && <span className="error-msg" style={{ bottom: 'unset' }}>{formBuildingData.error.building_streetNumber}</span>}
                                                    </div>
                                                    {/* Adresss */}
                                                    <div className="settingBuildingFormCol">
                                                        <TextField fullWidth className="inputField" label="Street Name" id="building_streetName" value={formBuildingData.building_streetName} onChange={handleForm} /><br />
                                                        {formBuildingData.error && formBuildingData.error.building_streetName && <span className="error-msg" style={{ bottom: 'unset' }}>{formBuildingData.error.building_streetName}</span>}
                                                    </div>
                                                    {/* City */}
                                                    <div className="settingBuildingFormCol">
                                                        <TextField fullWidth className="inputField" label="City" id="building_city" value={formBuildingData.building_city} onChange={handleForm} /><br />
                                                        {formBuildingData.error && formBuildingData.error.building_city && <span className="error-msg" style={{ bottom: 'unset' }}>{formBuildingData.error.building_city}</span>}
                                                    </div>
                                                    {/* State */}
                                                    <div className="settingBuildingFormCol">
                                                        <TextField fullWidth className="inputField" label="State" id="building_state"
                                                            value={formBuildingData.building_state} onChange={handleForm}>
                                                        </TextField>
                                                        <br />
                                                        {formBuildingData.error && formBuildingData.error.building_state && <span className="error-msg" style={{ bottom: 'unset' }}>{formBuildingData.error.building_state}</span>}
                                                    </div>
                                                    {/* Zip */}
                                                    <div className="settingBuildingFormCol">
                                                        <TextField fullWidth className="inputField" name="building_zip" label="Zip" id="building_zip" value={formBuildingData.building_zip} onChange={handleForm} /><br />
                                                        {formBuildingData.error && formBuildingData.error.building_zip && <span className="error-msg" style={{ bottom: 'unset' }}>{formBuildingData.error.building_zip}</span>}
                                                    </div>
                                                    {/* Email */}
                                                    <div className="settingBuildingFormCol">
                                                        <TextField fullWidth className="inputField" label="Email" id="building_email" value={formBuildingData.building_email} onChange={handleForm}></TextField><br />
                                                        {formBuildingData.error && formBuildingData.error.building_email && <span className="error-msg" style={{ bottom: 'unset' }}>{formBuildingData.error.building_email}</span>}
                                                    </div>
                                                    {/* Phone Number */}
                                                    <div className="settingBuildingFormCol">
                                                        <TextField fullWidth id="building_phone" name="building_phone" className="inputField" label="Phone Number"
                                                            value={formBuildingData.building_phone} onChange={handleForm}>
                                                        </TextField>
                                                        {/* {formBuildingData.error && formBuildingData.error.building_phone && <span className="error-msg" style={{ bottom: 'unset' }}>{formBuildingData.error.building_phone}</span>} */}
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="settingBuildingFormRow">
                                                    {/* Name */}
                                                    <div className="settingBuildingFormCol">
                                                        <span style={{ fontSize: '16px', color: '#848FAC' }}>Name</span> <br /> {buildingName}
                                                    </div>
                                                    {/* Adresss */}
                                                    <div className="settingBuildingFormCol">
                                                        <p><span style={{ fontSize: '16px', color: '#848FAC' }}>Street Name</span> <br />
                                                            {
                                                                buildingStreetName?.trim() != '' ? buildingStreetName : 'N/A'
                                                            }</p>
                                                    </div>
                                                    {/*Street Number */}
                                                    <div className="settingBuildingFormCol">
                                                        <p><span style={{ fontSize: '16px', color: '#848FAC' }}>Street Number</span> <br />
                                                            {
                                                                buildingStreetNumber?.trim() != null ? buildingStreetNumber : 'N/A'
                                                            }
                                                        </p>
                                                    </div>
                                                    {/* City */}
                                                    <div className="settingBuildingFormCol">
                                                        <p><span style={{ fontSize: '16px', color: '#848FAC' }}>City</span> <br />
                                                            {
                                                                buildingCity?.trim() != null ? buildingCity : 'N/A'
                                                            }
                                                        </p>
                                                    </div>
                                                    {/* State */}
                                                    <div className="settingBuildingFormCol">
                                                        <p><span style={{ fontSize: '16px', color: '#848FAC' }}>State</span> <br />
                                                            {
                                                                buildingState?.trim() != null ? buildingState : 'N/A'
                                                            }
                                                        </p>
                                                    </div>
                                                    {/* Zip */}
                                                    <div className="settingBuildingFormCol">
                                                        <p><span style={{ fontSize: '16px', color: '#848FAC' }}>Zip</span> <br />
                                                            {
                                                                buildingZip != null ? buildingZip : 'N/A'
                                                            }
                                                        </p>
                                                    </div>
                                                    {/* Email */}
                                                    <div className="settingBuildingFormCol">
                                                        <p><span style={{ fontSize: '16px', color: '#848FAC' }}>Email</span> <br />
                                                            {
                                                                buildingEmail?.trim() != null ? buildingEmail : 'N/A'
                                                            }
                                                        </p>
                                                    </div>
                                                    {/* Phone Number */}
                                                    <div className="settingBuildingFormCol">
                                                        <p><span style={{ fontSize: '16px', color: '#848FAC' }}>Phone Number</span> <br />{buildingPhone == null ? 'N/A' : buildingPhone}</p>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </StyledContentHeader>
                            </StyledContentWhiteBlock>
                        </div>
                        <div className="settingCol50">
                            <div className="settingRightBlock">
                                <div className="settingRightInfoBlock">
                                    <StyledContentWhiteBlockRightSecond className="settingRightInfoContainer">
                                        <StyledContentHeaderRight className="settingRightInfoHead">
                                            <div className="settingRightInfoTitleBlock">
                                                <h2 className="settingRightInfoTitle">Site Manager</h2>
                                            </div>
                                            <div className="settingRightInfoHeadActionBLock">
                                                <div className="search-section-setting">
                                                    <div className="input-group inputField searchInputField">
                                                        <input id="search" className="input-field" type="text" placeholder="Search here..." name="Search" value={search} onChange={filterProprtyManagers} />
                                                        <button className="btn search-icon">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.33268 15.0003C9.81183 15 11.2484 14.5049 12.4135 13.5937L16.0768 17.257L17.2552 16.0787L13.5919 12.4153C14.5035 11.25 14.999 9.81319 14.9994 8.33366C14.9994 4.65783 12.0085 1.66699 8.33268 1.66699C4.65685 1.66699 1.66602 4.65783 1.66602 8.33366C1.66602 12.0095 4.65685 15.0003 8.33268 15.0003ZM8.33268 3.33366C11.0902 3.33366 13.3327 5.57616 13.3327 8.33366C13.3327 11.0912 11.0902 13.3337 8.33268 13.3337C5.57518 13.3337 3.33268 11.0912 3.33268 8.33366C3.33268 5.57616 5.57518 3.33366 8.33268 3.33366Z" fill="#201D1D" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="settingRightInfoHeadActionBtnBlock">
                                                    <Button variant="contained" className="sbnBtn sbnBtnPrimary" onClick={(id,editID,name,email,image,mTitle) => onClickAddUser(1,"","","","","Add Site Manager")} >Add New</Button>
                                                </div>
                                            </div>
                                        </StyledContentHeaderRight>
                                        <div className="settingRightInfoRow">
                                            {
                                                buildingManager?.length > 0 ?
                                                    buildingManager?.map(managers => {
                                                        return (<div className="settingRightInfoItems">
                                                            {managers?.email != null && managers?.user_type == '1' &&
                                                                <StyledContentHeaderRight className="settingRightInfoList">
                                                                    <div className="settingRightInfoListBlock w-43">
                                                                        <CardHeader
                                                                            avatar={<Avatar alt={managers?.name} src={managers?.image} />}
                                                                            title={managers?.name}
                                                                            subheader="Site Manager"
                                                                        />
                                                                    </div>
                                                                    <div className="settingRightInfoListBlock w-43">
                                                                        {
                                                                            managers?.email ?
                                                                                <IconButton aria-label="settings" style={{
                                                                                    fontSize: '17px',
                                                                                    gap: '7%',
                                                                                }}>
                                                                                    <EmailIcon style={{ color: '#BC1E2D' }} />{managers?.email}</IconButton> : <span style={{ margin: '0', padding: '8px' }}>N/A</span>
                                                                        }
                                                                    </div>
                                                                    <div className="settingRightInfoListBlock w-15" >
                                                                        <div className='action-cell' align="right">
                                                                            <svg style={showAction === managers?.id ? { cursor: "pointer", fill: '#88352d' } : { cursor: "pointer" }} onClick={() => setShowAction(showAction != '' && managers?.id === showAction ? '' : managers?.id)} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" id="usersOptions" ref={userOptions}>
                                                                                <path d="M0 0h24v24H0z" fill="none" /><path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>
                                                                            <div className='action-main-setting-div' style={showAction === managers?.id ? {} : { display: "none" }}>
                                                                                <ul className='action-main-ul' id="usersOptions" ref={userOptions}>
                                                                                    <li id="usersOptions" ref={userOptions} className="action-main-ulli action-li-blue" onClick={(id,editID,name,email,image,mTitle) => onClickAddUser(1,managers?.id,managers?.name,managers?.email,managers?.image,"Edit Site Manager")}><DriveFileRenameOutlineIcon className="action-ulli-icon" />Edit Employee</li>

                                                                                    <li id="usersOptions" ref={userOptions} className="action-main-ulli action-li-red" onClick={(id) => handleDelete(managers?.id)}><DeleteIcon className="action-ulli-icon" />Delete Employee</li>
                                                                                    
                                                                                    <li id="usersOptions" ref={userOptions} className="action-main-ulli action-li-green" onClick={(id,emailType,isFloor) => handleClickPasswordOpen(managers?.id,1,0)}><SendIcon className="action-ulli-icon" /> Send Temp Password</li>
                                                                                    
                                                                                    {
                                                                                        (emailReg.test(managers?.email)) ? 
                                                                                            <li id="usersOptions" ref={userOptions} className="action-main-ulli action-li-green" onClick={(id,emailType,isFloor) => handleClickPasswordOpen(managers?.id,2,0)}><SendIcon className="action-ulli-icon" /> Send Temp Password To Building</li>
                                                                                            :
                                                                                        ''
                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </StyledContentHeaderRight>
                                                            }
                                                        </div>)

                                                    })
                                                    :
                                                    <div style={{ textAlign: 'center', margin: '20px' }}>No record found</div>
                                            }
                                        </div>
                                    </StyledContentWhiteBlockRightSecond>
                                    <SendTeamModal setPassword={setPassword} tpPassword={tpPassword} openSetPasswordOpen={openSetPasswordOpen} handleClickPasswordClose={handleClickPasswordClose} handleClickPasswordSubmit={handleClickPasswordSubmit} passwordError={passwordError} modalTitle={tempPasswordTitle} />
                                    {/* Delete Popup */}
                                    <Dialog open={Deleteopen} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                                        <DialogTitle id="alert-dialog-title">Are you sure want to delete this user ?</DialogTitle>
                                        <DialogActions>
                                            <Button onClick={handleCancelDelete}>No</Button>
                                            <Button onClick={handleConfirmDelete} autoFocus>Yes</Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                                <div className="settingRightInfoBlock">
                                    <StyledContentWhiteBlockRightSecond className="settingRightInfoContainer">
                                        <StyledContentHeaderRight className="settingRightInfoHead">
                                            <div className="settingRightInfoTitleBlock">
                                                <h2 className="settingRightInfoTitle">Floor Representative</h2>
                                            </div>
                                            <div className="settingRightInfoHeadActionBLock">
                                                <div className="search-section-setting">
                                                    <div className="input-group inputField searchInputField">
                                                        <input id="search" className="input-field" type="text" placeholder="Search here..." name="Search" value={searchFloor} onChange={filterFloorRepresentative} />
                                                        <button className="btn search-icon">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.33268 15.0003C9.81183 15 11.2484 14.5049 12.4135 13.5937L16.0768 17.257L17.2552 16.0787L13.5919 12.4153C14.5035 11.25 14.999 9.81319 14.9994 8.33366C14.9994 4.65783 12.0085 1.66699 8.33268 1.66699C4.65685 1.66699 1.66602 4.65783 1.66602 8.33366C1.66602 12.0095 4.65685 15.0003 8.33268 15.0003ZM8.33268 3.33366C11.0902 3.33366 13.3327 5.57616 13.3327 8.33366C13.3327 11.0912 11.0902 13.3337 8.33268 13.3337C5.57518 13.3337 3.33268 11.0912 3.33268 8.33366C3.33268 5.57616 5.57518 3.33366 8.33268 3.33366Z" fill="#201D1D" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="settingRightInfoHeadActionBtnBlock">
                                                    <Button variant="contained" className="sbnBtn sbnBtnPrimary" onClick={(id,editID,name,email,image,mTitle) => onClickAddUser(2,"","","","","Add Floor Representative")} >Add New</Button>
                                                </div>
                                            </div>
                                        </StyledContentHeaderRight>
                                        <div className="settingRightInfoRow">
                                            {
                                                floorRepresentative?.length > 0 ?
                                                floorRepresentative?.map(managers => {
                                                        return (<div className="settingRightInfoItems">
                                                            { 
                                                                <StyledContentHeaderRight className="settingRightInfoList">
                                                                    <div className="settingRightInfoListBlock w-43">
                                                                        <CardHeader
                                                                            avatar={<Avatar alt={managers?.name} src={managers?.image} />}
                                                                            title={managers?.name}
                                                                            subheader='Floor Representative'
                                                                        />
                                                                    </div>
                                                                    <div className="settingRightInfoListBlock w-43">
                                                                        {
                                                                            managers?.username ?
                                                                                <IconButton aria-label="settings" style={{
                                                                                    fontSize: '17px',
                                                                                    gap: '7%',
                                                                                }}>
                                                                                    <EmailIcon style={{ color: '#BC1E2D' }} />{managers?.username}</IconButton> : <span style={{ margin: '0', padding: '8px' }}>N/A</span>
                                                                        }
                                                                    </div>
                                                                    <div className="settingRightInfoListBlock w-15">
                                                                        <div className='action-cell' align="right">
                                                                            <svg style={showAction === managers?.id ? { cursor: "pointer", fill: '#88352d' } : { cursor: "pointer" }} onClick={() => setShowAction(showAction != '' && managers?.id === showAction ? '' : managers?.id)} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" id="usersOptions" ref={userOptions}>
                                                                                <path d="M0 0h24v24H0z" fill="none" /><path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>
                                                                            <div className='action-main-setting-div' style={showAction === managers?.id ? {} : { display: "none" }}>
                                                                                <ul className='action-main-ul' id="usersOptions" ref={userOptions}>
                                                                                    <li id="usersOptions" ref={userOptions} className="action-main-ulli action-li-blue" onClick={(id,editID,name,email,image,mTitle) => onClickAddUser(2,managers?.id,managers?.name,managers?.username,managers?.image,'Edit Floor Representative')}><DriveFileRenameOutlineIcon className="action-ulli-icon" />Edit Employee</li>

                                                                                    <li id="usersOptions" ref={userOptions} className="action-main-ulli action-li-red" onClick={(id) => handleDelete(managers?.id)}><DeleteIcon className="action-ulli-icon" />Delete Employee</li>

                                                                                    <li id="usersOptions" ref={userOptions} className="action-main-ulli action-li-green" onClick={(id,emailType,isFloor) => handleClickPasswordOpen(managers?.id,1,1)}><SendIcon className="action-ulli-icon" /> Reset Password</li>
                                                                                    {
                                                                                        (emailReg.test(managers?.username)) ? 
                                                                                            <li id="usersOptions" ref={userOptions} className="action-main-ulli action-li-green" onClick={(id,emailType,isFloor) => handleClickPasswordOpen(managers?.id,2,1)}><SendIcon className="action-ulli-icon" /> Send Temp Password To Building</li>
                                                                                        :
                                                                                        ''    
                                                                                    }       
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </StyledContentHeaderRight>
                                                            }
                                                        </div>)

                                                    })
                                                    :
                                                    <div style={{ textAlign: 'center', margin: '20px' }}>No record found</div>
                                            }
                                        </div>
                                    </StyledContentWhiteBlockRightSecond>
                                    <SendTeamModal setPassword={setPassword} tpPassword={tpPassword} openSetPasswordOpen={openSetPasswordOpen} handleClickPasswordClose={handleClickPasswordClose} handleClickPasswordSubmit={handleClickPasswordSubmit} passwordError={passwordError} modalTitle={tempPasswordTitle} />
                                    {/* Delete Popup */}
                                    <Dialog open={Deleteopen} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                                        <DialogTitle id="alert-dialog-title">Are you sure want to delete this user ?</DialogTitle>
                                        <DialogActions>
                                            <Button onClick={handleCancelDelete}>No</Button>
                                            <Button onClick={handleConfirmDelete} autoFocus>Yes</Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                            </div>
                        </div>
                    </div>
                </StyledContentMain >
            </StyledReportMainWrapper >
        </>

    )
}

export default Setting