import React, { useState, useEffect } from "react";
import '../../assets/css/building.css'
import '../../assets/css/fontawesome.css'
import '../../assets/css/fonts.css'
import ApiCall from "../../Api/index"
import { useSelector, useDispatch } from "react-redux";
import {
  setToken,
  setLoading,
  setUserDetails,
  setLogout, setSelectedBuildingId
} from "../../redux/user/user.action";
import Delivery from "./Delivery";
import NotAuthorized from "./NotAuthorized";
import { useParams } from 'react-router';
import { useRef } from 'react';
import moment from "moment";  

function Index() {
  const [deliveryCount, setDeliveryCount] = useState([]);
  const token = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch()
  const { id, month } = useParams();
  const [sortType, setSortType] = useState(1)
  const ref = useRef(null);
  const [logData, setLogData] = useState();
  const [incomingData, setIncomingData] = useState([]);
  const [outgoingData, setOutgoingData] = useState([]);
  const [escortsData, setEscortsData] = useState([]);
  const [tenantActivityLogs, setTenantActivityLogs] = useState([]);
  const [standradActivityLogs, setStandradActivityLogs] = useState([]);
  const [escortActivityLogs, setEscortActivityLogs] = useState([]);
  const [labels, setLabels] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [isOpen, setIsOpen] = useState(false);
  const [customYear, setCustomYear] = useState(false);
  const [customMonth, setCustomMonth] = useState(false);
  const [datePickerLable,setDatePickerLable] = useState('This Month');
  const [incomingCount,setIncomingCount] = useState(0);
  const [outgoingCount,setOutgoingCount] = useState(0);
  const [escortCount,setEscortCount] = useState(0);
  const loggedInBuildingID = useSelector((state) => state?.user?.userDeatils?.building_id);
  const userType = useSelector((state) => state?.user?.userDeatils?.user_type);
  const [isAllowbuilding,setIsAllowBuilding] = useState(1);

  //alert(loggedInBuildingID)
  
  useEffect(() => {
    if(loggedInBuildingID == id || userType == '3' ) {
      setIsAllowBuilding(1);
    } else if(userType == '4') {
      setIsAllowBuilding(1);
    }
  })

  useEffect(() => {
    (async () => {
      dispatch(setLoading(true))
      dispatch(setSelectedBuildingId(id))
      const deliveryCount = await ApiCall('v1/delivery-count-web/' + id + '/' + month + '/2/'+startDate+'/'+endDate, 'get', null, token);
      if (deliveryCount.data) {
        console.log("count data"+JSON.stringify(deliveryCount?.data?.RESULT));
        setIncomingCount(deliveryCount?.data?.RESULT[0]?.incoming);
        setOutgoingCount(deliveryCount?.data?.RESULT[0]?.outgoing);
        setEscortCount(deliveryCount?.data?.RESULT[0]?.escort);
      } else {
        if (deliveryCount?.error?.response?.status === 401) {
          dispatch(setLogout());
        } else if (deliveryCount.error == 'Unauthorized') {
          dispatch(setLogout())
        }
        setDeliveryCount([])
      }
      dispatch(setLoading(false))
    })()
  }, [id,startDate,endDate])

  useEffect(() => {
    (async () => {
      
      let activity = await ApiCall('v1/manage-package-web/activity/log/' + id + '/'+sortType + '/' + startDate + '/' + endDate, 'get', null, token);
      

      setIncomingData(activity?.data?.RESULT?.incoming);
      setOutgoingData(activity?.data?.RESULT?.outgoing);
      setEscortsData(activity?.data?.RESULT?.escort);

      let activityForReport = await ApiCall('v1/manage-package-report-web/activity/log/' + id + '/'+sortType + '/' + startDate + '/' + endDate, 'get', null, token);

      let tenantActivityLogsAry = activityForReport?.data?.RESULT?.tenantActivityLogs.map(obj => Object.values(obj));
      setTenantActivityLogs(tenantActivityLogsAry);
      let standradActivityLogsAry = activityForReport?.data?.RESULT?.standradActivityLogs.map(obj => Object.values(obj));
      setStandradActivityLogs(standradActivityLogsAry);
      let escortActivityLogsAry = activityForReport?.data?.RESULT?.escortActivityLogs.map(obj => Object.values(obj));
      setEscortActivityLogs(escortActivityLogsAry);
      console.log('activity?.data?.RESULT:', activity?.data?.RESULT)
      setLabels(activity?.data?.RESULT?.lables);

      if (activity?.data) {
        setLogData(activity?.data?.RESULT?.data);
      } else {
        if (activity?.error?.response?.status === 401) {
          dispatch(setLogout());
        } else if (activity.error == 'Unauthorized') {
          dispatch(setLogout())
        }
        setLogData([])
      }
    })()

  }, [id, sortType,startDate, endDate])

  const customPicker = (optionID,quarterStartDate='',targetYear='') => {
   console.log("optionID",quarterStartDate)
    if(optionID == '1') { // current month
      setStartDate(moment().startOf('month').format('YYYY-MM-DD'));
      setEndDate(moment().endOf('month').format('YYYY-MM-DD'));
      setSortType(1);
      setDatePickerLable('This Month');
    } else if(optionID == '2') { //prev month
      setStartDate(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'))
      setEndDate(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
      setSortType(1);
      setDatePickerLable('Last Month');
    } else if(optionID == '3') {  //current year
      setStartDate(moment().startOf('year').format('YYYY-MM-DD'))
      setEndDate(moment().endOf('year').format('YYYY-MM-DD'));
      setSortType(2);
      setDatePickerLable('This Year');
    } else if(optionID == '4') {  //prev year
      setStartDate(moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'))
      setEndDate(moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'));
      setSortType(2);
      setDatePickerLable('Last Year');
    } else if(optionID == '5') { //quarter
      setStartDate(quarterStartDate[0]); 
      setEndDate(quarterStartDate[1]);
      //console.log('Selected Preset:', option.value+ ' '+option.valueEnd);
      setSortType(3); 
      setDatePickerLable(targetYear);
    } else if(optionID == '6') {   //custom year
      setStartDate(quarterStartDate+'-01-01')
      setEndDate(quarterStartDate+'-12-31');
      setSortType(2);
      setDatePickerLable(quarterStartDate);
    } else if(optionID == '7') {  //custom month
      const targetMonth = quarterStartDate;
      const startDate = moment(`${targetYear}-${moment().month(targetMonth).format('MM')}-01`, 'YYYY-MM-DD');
      const endDate = startDate.clone().endOf('month').format('YYYY-MM-DD');
      setStartDate(startDate.format('YYYY-MM-DD'))
      setEndDate(endDate)
      setSortType(1);
      setDatePickerLable(moment().month(targetMonth).format('MMMM')+', '+targetYear);
    }      
    setIsOpen(false);
    setCustomMonth(false)
    setCustomYear(false)
    //console.log('Selected Preset:', dbStartDate+ ' '+dbEndDate);
    document.getElementById("customPicker").classList.remove('show')
    document.getElementById("datePickerDropdownMenu").classList.remove('show')
  };

  return ( isAllowbuilding ?
    <Delivery deliveryCount={deliveryCount} logs={logData} incomingData={incomingData} outgoingData={outgoingData} escortsData={escortsData} tenantActivityLogs={tenantActivityLogs} standradActivityLogs={standradActivityLogs} escortActivityLogs={escortActivityLogs} labels={labels} customPicker={customPicker} customYear={customYear} customMonth={customMonth} setCustomYear={setCustomYear} setCustomMonth={setCustomMonth} datePickerLable={datePickerLable} incomingCount={incomingCount} outgoingCount={outgoingCount} escortCount={escortCount} />
    :
    <NotAuthorized/>
  )
}
export default Index