import React, { useState, useEffect } from "react";
import '../../assets/css/building.css'
import '../../assets/css/fontawesome.css'
import '../../assets/css/fonts.css'
import User from './User'
import ApiCall from "../../Api/index"
import { useSelector, useDispatch } from "react-redux";
import { setLoading, setLogout } from "../../redux/user/user.action";
import AddUserModal from "./AddUserModal";
import ToastAlert from "../../common/ToastAlert";
import { confirmAlert } from 'react-confirm-alert';
import { validateAll } from "indicative/validator";
import { Messages } from "../../common/msg"
import { useNavigate } from "react-router-dom";
import PropertyManagerModal from "./PropertyManagerModal";  

// import 'bootstrap/dist/css/bootstrap.min.css';



function Index() {

    const token = useSelector((state) => state.user.userToken);
    const selectedBuildingId = useSelector((state) => state.user.selectedBuildingId);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [userList, setUserList] = useState([])
    const [allUserList, setAllUserList] = useState([])
    const [allUserListWithoutFilter, setAllUserListWithoutFilter] = useState(allUserList);

    const [modalShow, setModalShow] = useState(false);
    const [propertyManagerModalShow, setPropertyManagerModalShow] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchVal, setSearchVal] = useState('')


    const [formData, setFormData] = useState({
        type: 2,
        user_id: [],
        email: "",
        firstName: "",
        lastName: "",
        error: {}
    });

    const { email, firstName, lastName, type, user_id } = formData;

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };

    const handleForm = (e) => {
        if (e.target.type == "checkbox") {
            setFormData({
                ...formData,
                error: {},
            });
            if (e.target.checked) {
                formData.user_id.push(e.target.value)
            } else {
                formData.user_id.splice(formData.user_id.indexOf(e.target.value), 1);
            }
        } else {
            setFormData({
                ...formData,
                [e.target.id]: e.target.value,
            });
        }
    };

    const onHide = () => {
        setModalShow(false)
        setFormData({
            ...formData,
            type: 2,
            user_id: [],
            email: "",
            firstName: "",
            lastName: "",
            error: {}
        })
    }


    const onHidePropertyManagerModal = () => {
        setPropertyManagerModalShow(false)
        setSearchVal('')
        setFormData({
            ...formData,
            email: "",
        });
        setAllUserList(allUserListWithoutFilter)
    }

    const submitForm = async () => {


        let rules = {}
        if (type == 1) {
            rules = {
                email: "required|email",
                firstName: "required",
                lastName: "required",
            };
        } else if (type == 2) {
            rules = {
                user_id: "required|array|min:1",
            };
        }

        const message = {
            "email.required": "Email " + Messages.IS_REQUIRED,
            "email.email": "Email " + Messages.IS_INVALID,
            "firstName.required": "First Name " + Messages.IS_REQUIRED,
            "lastName.required": "Last Name " + Messages.IS_REQUIRED,
            "user_id.required": "User " + Messages.IS_REQUIRED,
            "user_id.min": Messages.SELECT_AT_LEAST_ONE_USER,
        };

        validateAll(formData, rules, message)
            .then(async () => {
                setFormData({
                    ...formData,
                    error: {},
                });
                dispatch(setLoading(true))
                let payload = {}
                if (type == 1) {
                    payload = {
                        type: type,
                        first_name: firstName,
                        last_name: lastName,
                        email: email,
                        building_id: selectedBuildingId
                    }
                } else if (type == 2) {
                    payload = {
                        type: type,
                        user_id: user_id,
                        building_id: selectedBuildingId
                    }
                }
                const save = await ApiCall('v1/building-manager', 'post', payload, token);
                if (save.data) {
                    onHide()
                    setModalShow(false)
                    onHidePropertyManagerModal(false)
                    ToastAlert({ title: "", msg: save?.data?.MESSAGE, msgType: "success" })
                    await getUserList()
                    dispatch(setLoading(false))
                } else if (save?.error?.response?.status === 401) {
                    dispatch(setLogout());
                } else {
                    ToastAlert({ title: "", msg: save?.error?.response?.data?.message || save?.error?.response?.data?.MESSAGE || Messages.SOMETHING_WENT_WRONG, msgType: "error" })
                    dispatch(setLoading(false))
                }
            })
            .catch((errors) => {
                const formaerrror = {};
                if (errors.length) {
                    errors.forEach(function (value) {
                        formaerrror[value.field] = value.message;
                    });
                } else {
                    ToastAlert({ title: "", msg: Messages.SOMETHING_WENT_WRONG, msgType: "error" })
                }

                setFormData({
                    ...formData,
                    error: formaerrror,
                });
            });
        setSearchVal('')
        setFormData({
            ...formData,
            email: "",
        });
        setAllUserList(allUserListWithoutFilter)

    };

    const deleteAdminUser = async (id) => {
        confirmAlert({
            title: 'Confirm to Archive?',
            message: 'Are you sure you want to archive this record ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        dispatch(setLoading(true))
                        const archive = await ApiCall('v1/building-manager/' + id + '/' + selectedBuildingId, 'delete', null, token);
                        if (archive.data) {
                            setModalShow(false)
                            ToastAlert({ title: "", msg: archive?.data?.MESSAGE, msgType: "success" })
                            await getUserList()
                            dispatch(setLoading(false))
                        } else if (archive?.error?.response?.status === 401) {
                            dispatch(setLogout());
                        } else {
                            ToastAlert({ title: "", msg: archive?.error?.response?.data?.message || archive?.error?.response?.data?.MESSAGE || Messages.SOMETHING_WENT_WRONG, msgType: "error" })
                            dispatch(setLoading(false))
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {

                    }
                }
            ]
        });
    }

    const getUserList = async (page = 1) => {
        dispatch(setLoading(true))
        const fetchUser = await ApiCall('v1/building-manager?page=' + page + '&building_id=' + selectedBuildingId, 'get', null, token);
        if (fetchUser.data) {
            setUserList(fetchUser?.data?.RESULT?.data);
            if (fetchUser?.data?.Total > 0) {
                let numberPagination = Math.ceil(fetchUser?.data?.Total / 10);
                setTotalRecords(numberPagination);
            } else {
                setTotalRecords(0);
            }
            dispatch(setLoading(false))
        } else {
            if (fetchUser?.error?.response?.status === 401) {
                dispatch(setLogout());
            } else if (fetchUser.error == 'Unauthorized') {
                dispatch(setLogout())
            } else if (fetchUser.CODE != 200) {
                //ToastAlert({ title: "Users list", msg: fetchUser?.error?.response?.data?.MESSAGE, msgType: "error" })
            }
            dispatch(setLoading(false))
            setUserList([])
        }
    }

    const getAllUserList = async () => {
        dispatch(setLoading(true))
        const fetchUser = await ApiCall('v1/building-manager?page=1&building_id=', 'get', null, token);
        if (fetchUser.data) {
            setAllUserList(fetchUser?.data?.RESULT?.data || fetchUser?.data?.RESULT);
            setAllUserListWithoutFilter(fetchUser?.data?.RESULT?.data || fetchUser?.data?.RESULT);
            dispatch(setLoading(false))
        } else {
            if (fetchUser?.error?.response?.status === 401) {
                dispatch(setLogout());
            } else if (fetchUser.error == 'Unauthorized') {
                dispatch(setLogout())
            } else if (fetchUser.CODE != 200) {
            }
            dispatch(setLoading(false))
            setAllUserList([])
            setAllUserListWithoutFilter([])
        }
    }

    useEffect(() => {
        if (!selectedBuildingId) {
            navigate("/home")
        }
        (async () => {
            await getUserList(1)
            await getAllUserList()
        })()
    }, [])


    

    const onClickAddUser = () => {
        // alert("test")
        // setModalShow(true)
        setPropertyManagerModalShow(true)
    }

    const onClickAddNewUser = () => {
        setFormData({
            ...formData,
            type: 1,
            email : validateEmail(searchVal) ?  searchVal : ''
        })
        setModalShow(true)
    }


    const resendPasswordLink = async (email) => {
        dispatch(setLoading(true))
        const fetchUser = await ApiCall('v1/building-manager-resend-actication', 'post', { email: email }, token);
        if (fetchUser?.data?.CODE == '200') {
            ToastAlert({ title: "", msg: fetchUser?.data?.MESSAGE, msgType: "success" })
            dispatch(setLoading(false))
        } else {
            if (fetchUser?.error?.response?.status === 401) {
                dispatch(setLogout());
            } else if (fetchUser.error == 'Unauthorized') {
                dispatch(setLogout())
            } else if (fetchUser.CODE != 200) {
                ToastAlert({ title: "Error", msg: fetchUser?.error?.response?.data?.MESSAGE, msgType: "error" })
            }
            dispatch(setLoading(false))
        }
    }
    function search(event) {
        setSearchVal(event.target.value)
        if (event.target.value && event.target.value != '') {
            setFormData({
                ...formData,
                error: {},
            });
            const filtered = allUserList.filter(entry => Object.values(entry).some(val => val?.toString()?.toLowerCase().trim().includes(event.target.value.toLowerCase().trim())));
            setAllUserList(filtered)
        } else {
            setAllUserList(allUserListWithoutFilter)
        }
    }

    return (
        <>
            <AddUserModal show={modalShow} formData={formData} handleForm={handleForm} submitForm={submitForm} onHide={() => onHide()} searchVal={searchVal} />    
            <PropertyManagerModal search={search} setSearchVal={setSearchVal} searchVal={searchVal} show={modalShow} setAllUserList={setAllUserList} setAllUserListWithoutFilter={setAllUserListWithoutFilter} allUserListWithoutFilter={allUserListWithoutFilter} onClickAddNewUser={onClickAddNewUser} formData={formData} handleForm={handleForm} submitForm={submitForm} allUserList={allUserList} propertyManagerModalShow={propertyManagerModalShow} onHidePropertyManagerModal={() => onHidePropertyManagerModal()} onHide={() => onHide()} />
            <User userList={userList} deleteAdminUser={deleteAdminUser} getUserList={getUserList} onClickAddUser={onClickAddUser} resendPasswordLink={resendPasswordLink} totalRecords={totalRecords} />
        </>
    )

}

export default Index