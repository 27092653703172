import { Link } from "react-router-dom"
// import Logo from "../../components/Logo"
import LogoImg from "../assets/images/supreme_point.png"
import { setLogout } from "../redux/user/user.action"
import { useSelector, useDispatch } from "react-redux"
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useLocation } from 'react-router-dom'
import { useState, useEffect } from "react";

import Home from "../assets/images/home.png"
import HomeSelected from "../assets/images/home_selected.png"
import UserSelected from "../assets/images/user_selected.png"
import User from "../assets/images/user.png"
import SettingsSelected from "../assets/images/settings_selected.png"
import Settings from "../assets/images/settings.png"


function Sidebar() {
    console.log("user menu is enable")
    const dispatch = useDispatch()
    const location = useLocation();
    const [currentLocation, setCurrentLocation] = useState();
    const userType = useSelector((state) => state?.user?.userDeatils?.user_type);
    const [userRole, setUserRole] = useState(userType);
    const loggedInBuildingID = useSelector((state) => state?.user?.userDeatils?.building_id);
    const [isAllowbuilding,setIsAllowBuilding] = useState(1);

    const id = location.pathname.split('/')?.[2]

    useEffect(() => {
        if(loggedInBuildingID == id || userType == '3' ) {
            setIsAllowBuilding(1);
        }
    })

    useEffect(() => {
        setCurrentLocation(location.pathname);
    }, [location])

    let dt = new Date();
    let month = (dt.getMonth() + 1).toString().padStart(2, "0");

    const logout = () => {
        confirmAlert({
            title: 'Confirm to logout ?',
            message: 'Are you sure you want to log out?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(setLogout())
                    }
                },
                {
                    label: 'No',
                    onClick: () => {

                    }
                }
            ]
        });
    }

    return (
        <div className="sidebar-wrapper" id="sidebar-main">
            {/* <div className="sidebar-overlay desktop-hide"></div> */}
            <div className="sidebar-main">
                <div className="sidebar-head">
                    <Link to="/home">
                        <img src={LogoImg} alt="logo" />
                    </Link>
                </div>

                {
                    isAllowbuilding ?
                    <div className="sidebar-body">
                        <ul className="sidebar-items">
                            <li className="sidebar-item">
                                {/* "#BC1E2D" */}
                                <Link to={`/metabase/${id}/${month}`}>
                                    <span className="sidebar-item-icon fill-color"  >
                                        {
                                            (currentLocation == '/' || currentLocation?.includes('metabase')) ?
                                                <img src={HomeSelected} width="24" height="24" />
                                            :
                                                <img src={Home} width="24" height="24" />
                                        }
                                        
                                        {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke={currentLocation == '/' || currentLocation?.includes('deliver') ? "#BC1E2D" : ''} d="M20.83 8.01002L14.28 2.77002C13 1.75002 11 1.74002 9.72996 2.76002L3.17996 8.01002C2.23996 8.76002 1.66996 10.26 1.86996 11.44L3.12996 18.98C3.41996 20.67 4.98996 22 6.69996 22H17.3C18.99 22 20.59 20.64 20.88 18.97L22.14 11.43C22.32 10.26 21.75 8.76002 20.83 8.01002ZM12.75 18C12.75 18.41 12.41 18.75 12 18.75C11.59 18.75 11.25 18.41 11.25 18V15C11.25 14.59 11.59 14.25 12 14.25C12.41 14.25 12.75 14.59 12.75 15V18Z" fill={currentLocation == '/' || currentLocation?.includes('deliver') ? "#BC1E2D" : "#C6C6C6"} />
                                        </svg> */}
                                    </span>
                                    <span className="sidebar-item-text text-hide">Home</span>
                                </Link>
                            </li>
                            {
                                userRole == '3' &&
                                <li className="sidebar-item">
                                    <Link to={`/user/${id}`}>
                                        <span className="sidebar-item-icon stroke-color">
                                            {
                                                currentLocation == '/' || currentLocation?.includes('user') ?
                                                    <img src={UserSelected} width="24" height="24" />
                                                :   
                                                    <img src={User} width="24" height="24" /> 
                                            }
                                            {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill={currentLocation == '/' || currentLocation?.includes('user') ? "#BC1E2D" : "#C6C6C6"} stroke={currentLocation == '/' || currentLocation?.includes('user') ? "#BC1E2D" : ''} d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path fill={currentLocation == '/' || currentLocation?.includes('user') ? "#BC1E2D" : "#C6C6C6"} stroke={currentLocation == '/' || currentLocation?.includes('user') ? "#BC1E2D" : ''} d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg> */}

                                        </span>
                                        <span className="sidebar-item-text text-hide">User</span>

                                    </Link>
                                </li>
                            }
                            <li className="sidebar-item">
                                <Link to={`/setting/${id}`}>
                                    <span className="sidebar-item-icon stroke-color">
                                        {
                                                currentLocation == '/' || currentLocation?.includes('setting') ?
                                                    <img src={SettingsSelected} width="24" height="24" />
                                                :
                                                    <img src={Settings} width="24" height="24" />
                                        }

                                    </span>
                                    <span className="sidebar-item-text text-hide">Settings</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    :
                    ''
                }
                {/* <div className="sidebar-foot" onClick={logout}>
                    <a href="javascript:void(0);">
                        <span className="sidebar-item-icon stroke-color">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.36 6.64C19.6184 7.89879 20.4753 9.50244 20.8223 11.2482C21.1693 12.9939 20.9909 14.8034 20.3096 16.4478C19.6284 18.0921 18.4748 19.4976 16.9948 20.4864C15.5148 21.4752 13.7749 22.0029 11.995 22.0029C10.2151 22.0029 8.47515 21.4752 6.99517 20.4864C5.51519 19.4976 4.36164 18.0921 3.68036 16.4478C2.99909 14.8034 2.82069 12.9939 3.16772 11.2482C3.51475 9.50244 4.37162 7.89879 5.63 6.64" stroke="#BC1E2D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12 2V12" stroke="#BC1E2D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </span>
                        <span className="sidebar-item-text text-hide">Power</span>
                    </a>
                </div> */}
            </div>
        </div>
    )
}
export default Sidebar