import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import RedCloseWithCircle from "../../assets/images/red_close_with_circle.png"
import EmailPng from "../../assets/images/Message.png"
import { useState } from 'react';
import userImage from "../../assets/images/profile.png"



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));



function BootstrapDialogTitle(props) {
    const { children, onClose, submitForm, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>

            {children}
            {onClose ? (
                <img src={RedCloseWithCircle} alt="" onClick={onClose} style={{ position: 'absolute', right: 8, top: 10, width: '40px', height: '40px', cursor: "pointer" }} />
            ) : null}
            <button
                style={{
                    padding: '4px 20px',
                    // width: '240px',
                    // height: '50px',
                    background: '#BC1E2D',
                    borderRadius: '100px',
                    border: 'unset',
                    color: 'white',
                    cursor: "pointer",
                    position: 'absolute', right: 60, top: 10,
                }}
                onClick={() => submitForm()}
            >Save</button>
        </DialogTitle>
    );
}

function PropertyManagerModal({ search, setSearchVal, searchVal, show, onHide, handleForm, formData, allUserListWithoutFilter, submitForm, onHidePropertyManagerModal, propertyManagerModalShow, allUserList, setAllUserList, onClickAddNewUser }) {




    return (
        <BootstrapDialog
            onClose={onHidePropertyManagerModal}
            aria-labelledby="customized-dialog-title"
            open={propertyManagerModalShow}
            PaperProps={{
                sx: {
                    width: "1000px",
                }
            }}
        >
            <BootstrapDialogTitle id="customized-dialog-title theme-dialog-title" submitForm={submitForm} onClose={onHidePropertyManagerModal} style={{ fontFamily: "unset" }}>
                Property Manager
            </BootstrapDialogTitle> 
            <DialogContent>
                <div class="search-section" style={{ maxWidth: "unset", marginBottom: "10px" }}>
                    <div class="input-group">
                        <input id="search" class="input-field" type="text" placeholder="Search here..." name="Search" value={searchVal} onChange={(e) => search(e)} />
                        <button class="btn search-icon"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.33268 15.0003C9.81183 15 11.2484 14.5049 12.4135 13.5937L16.0768 17.257L17.2552 16.0787L13.5919 12.4153C14.5035 11.25 14.999 9.81319 14.9994 8.33366C14.9994 4.65783 12.0085 1.66699 8.33268 1.66699C4.65685 1.66699 1.66602 4.65783 1.66602 8.33366C1.66602 12.0095 4.65685 15.0003 8.33268 15.0003ZM8.33268 3.33366C11.0902 3.33366 13.3327 5.57616 13.3327 8.33366C13.3327 11.0912 11.0902 13.3337 8.33268 13.3337C5.57518 13.3337 3.33268 11.0912 3.33268 8.33366C3.33268 5.57616 5.57518 3.33366 8.33268 3.33366Z" fill="#201D1D"></path></svg></button>
                    </div>
                    {formData.error && formData.error.user_id && <span className="error-msg" style={{ bottom: "unset" }}>{formData.error.user_id}</span>}
                </div>
                <div className="table-responsive">
                    <table className="cus-data-table" style={{ marginTop: "0px" }}>

                        <tbody>
                            {
                                allUserList?.length > 0 ?
                                    allUserList.map((user, key) => {
                                        return (
                                            <tr key={key}>
                                                <td><input type="checkbox" onClick={(e) => handleForm(e)} name='radio' value={user.id} /></td>
                                                <td>
                                                    <div class="drop_box"><span class="image_box" href="javascript:void(0)"><img src={userImage} alt="img" class="image" /></span><div class="country_info"><a class="cnt_name" href="#" style={{ textDecoration: 'unset', cursor: 'default' }}>{user.name}</a></div></div>
                                                </td>
                                                <td>
                                                    {
                                                        user.email ?
                                                            <>
                                                                <img style={{ width: '16px', height: '14px' }} src={EmailPng} />
                                                                &nbsp;&nbsp;{user.email}</>
                                                            :
                                                            '-'
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }) :
                                    <tr>
                                        <td colSpan="6" style={{ textAlign: "center", border: 'unset' }}>
                                            <button
                                                style={{
                                                    padding: '13px 24px',
                                                    width: '240px',
                                                    height: '50px',
                                                    background: '#BC1E2D',
                                                    borderRadius: '100px',
                                                    border: 'unset',
                                                    color: 'white',
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => onClickAddNewUser()}
                                            >Create Propery Manager Now</button></td>
                                    </tr>
                            }

                        </tbody>
                    </table>
                </div>


            </DialogContent>
        </BootstrapDialog >

    );
}
export default PropertyManagerModal