import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RedCloseWithCircle from "../../assets/images/red_close_with_circle.png"


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                // <IconButton
                //     aria-label="close"
                //     onClick={onClose}
                //     sx={{
                //         position: 'absolute',
                //         right: 8,
                //         top: 8,
                //         color: (theme) => theme.palette.grey[500],
                //     }}
                // >
                // </IconButton>
                <img src={RedCloseWithCircle} alt="" onClick={onClose} style={{ position: 'absolute', right: 8, top: 10, width:'40px', height: '40px', cursor: "pointer" }} />
            ) : null}
        </DialogTitle>
    );
}



function AddUserModal({ show, onHide, handleForm, submitForm, formData,searchVal }) {

    return (

        <BootstrapDialog
            onClose={onHide}
            aria-labelledby="customized-dialog-title"
            open={show}
            PaperProps={{
                sx: {
                    width: "446px",
                }
            }}
        >
            <BootstrapDialogTitle id="customized-dialog-title theme-dialog-title" onClose={onHide} style={{ fontFamily: "unset" }}>
                Add Property Manager
            </BootstrapDialogTitle> 
            <DialogContent style={{ paddingTop: "20px" }}>
                <form name="signup" className="sign-up">
                    <div className="form-control" style={{ borderRadius: "100px" }}>
                        <input type="text" name="firstName" id='firstName' className="form-input" placeholder="none" value={formData.firstName} onChange={handleForm} />
                        <label htmlFor="firstName" className="form-label" style={{ background: "#ffffff" }}>First Name</label>
                        {formData.error && formData.error.firstName && <span className="error-msg">{formData.error.firstName}</span>}
                    </div>
                    <div className="form-control" style={{ borderRadius: "100px" }}>
                        <input type="text" name="lastName" id='lastName' className="form-input" placeholder="none" value={formData.lastName} onChange={handleForm} />
                        <label htmlFor="lastName" className="form-label" style={{ background: "#ffffff" }}>Last Name</label>
                        {formData.error && formData.error.lastName && <span className="error-msg">{formData.error.lastName}</span>}
                    </div>
                    <div className="form-control" style={{ borderRadius: "100px" }}>
                        <input type="email" name="email" id='email' className="form-input" placeholder="none" value={formData.email} onChange={handleForm} />
                        <label htmlFor="email" className="form-label" style={{ background: "#ffffff" }}>Email</label>
                        {formData.error && formData.error.email && <span className="error-msg">{formData.error.email}</span>}
                    </div>

                </form>

            </DialogContent>
            <DialogActions style={{ borderTop: "unset", gap: "35%", paddingBottom: "30px" }}>

                <button
                    style={{
                        padding: '13px 24px',
                        width: '240px',
                        height: '50px',
                        background: '#BC1E2D',
                        borderRadius: '100px',
                        border: 'unset',
                        color: 'white',
                        cursor: "pointer",
                    }}
                    onClick={() => submitForm()}

                // onClick={onClickAddUser}

                >Create Propery Manager Now</button>
            </DialogActions>
        </BootstrapDialog >

    );
}
export default AddUserModal