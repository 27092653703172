import { Route, Routes } from 'react-router-dom'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import Building from '../view/building/index'
import Setting from '../view/setting/Index'
import Delivery from '../view/deliveries/index'
import Metabase from '../view/metabase/index'
import { useLocation,useSearchParams,useNavigate } from 'react-router-dom'
import User from '../view/user/Index'
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react"
import { confirmAlert } from 'react-confirm-alert';
import { setLogout, setSelectedBuildingId } from "../redux/user/user.action"

export default function PrivateRoutes() {

    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isReset = searchParams.get("reset");
    const isReport = searchParams.get("report");
    const buildingID = useSelector((state) => state.user.selectedBuildingId);


    if(location.pathname == '/login' && isReset == '1') {
        confirmAlert({
            title: '',
            message: 'Active session detected. Please log out before accessing the password reset page.',
            buttons: [
              {
                label: 'Logout',
                onClick: () => {
                    dispatch(setLogout())
                    navigate('/login');
                }
              },
              {
                label: 'Cancel',
                onClick: () => {
                    navigate('/home');
                }
              }
            ]
          });
    }

    return (
        <div className="page-wrapper_ dashboard-wrapper">
            <main className="page-main">
                {
                    location.pathname != '/home' ? <Sidebar /> : ''
                }

                <div className="column-main">
                    <Header />
                    <Routes>
                        <Route path="/building" element={<Building />} />
                        <Route path="/deliver/:id/:month" element={<Delivery />} />
                        <Route path="/home" element={<Building />} />
                        <Route path="/user/:id" element={<User />} />
                        <Route path="/setting/:id" element={<Setting />} />
                        <Route path="/metabase/:id/:month" element={<Metabase />} />
                        {/* <Route path="/reset" element={<Reset />} /> */}
                    </Routes>
                </div>
            </main>
        </div>
    )
}