import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RedCloseWithCircle from "../../assets/images/red_close_with_circle.png"
import { FormControl, InputLabel, MenuItem, Button } from '@mui/material';
import { Select } from 'antd';
import { Messages } from '../../common/msg';
import { useState } from 'react';
import AWS from 'aws-sdk'
import hideIcon from "../../assets/images/hide.png"



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <img src={RedCloseWithCircle} alt="" onClick={onClose} style={{ position: 'absolute', right: 8, top: 10, width: '40px', height: '40px', cursor: "pointer" }} />
            ) : null}
        </DialogTitle>
    );
}


function AddUserModal({ show, onHide, handleForm, submitForm, formData, handleFileInput, selectedFile, errorMessage, editUserID, userModalTitle, imagePreview, userType,showPassword }) {


    return (

        <BootstrapDialog onClose={onHide} aria-labelledby="customized-dialog-title" open={show} PaperProps={{ sx: { width: "446px", } }}>
            <BootstrapDialogTitle id="customized-dialog-title theme-dialog-title" onClose={onHide} style={{ fontFamily: "unset" }}>
                {userModalTitle}
            </BootstrapDialogTitle>
            <DialogContent style={{ paddingTop: "20px" }}>
                <form name="signup" className="sign-up">
                    <div className="form-control" style={{ borderRadius: "100px", marginBottom:"36px" }}>
                        <input type="text" name="firstName" id='firstName' className="form-input" placeholder="none" maxLength="20" value={formData.firstName} onChange={handleForm} />
                        <label htmlFor="firstName" className="form-label" style={{ background: "#ffffff" }}>Name</label>
                        {formData.error && formData.error.firstName && <span className="error-msg">{formData.error.firstName}</span>}
                    </div>
                    <div className="form-control" style={{ borderRadius: "100px",marginBottom:"36px" }}>
                        {userType === 1 ? (
                            <>
                                <input type="email" name="email" id='email' className="form-input" placeholder="none" value={formData.email} onChange={handleForm} readOnly={editUserID != undefined && editUserID != '' ? 'readonly' : ''} />
                                <label htmlFor="email" className="form-label" style={{ background: "#ffffff" }}>Email</label>
                                {formData.error && formData.error.email && <span className="error-msg">{formData.error.email}</span>}
                            </>
                        ) : ( 
                            <>
                                <input type="username" name="username" id='email' className="form-input" placeholder="none" value={formData.email} onChange={handleForm} />
                                <label htmlFor="username" className="form-label" style={{ background: "#ffffff" }}>User Name</label>
                                {formData.error && formData.error.email && <span className="error-msg">{formData.error.email}</span>}
                            </>
                        )}
                    </div>
                    <div className="form-control" style={{ borderRadius: "100px",marginBottom:"36px" }}>
                        <input type={formData.input_type ? 'password':'text'} name="password" id='password' className="form-input" placeholder="none" value={formData.password} onChange={handleForm} />
                        <label htmlFor="email" className="form-label" style={{ background: "#ffffff" }}>Password</label>
                        <span className="psw-icon" style={{ cursor : 'pointer',zIndex:2 }} onClick={showPassword}>
                        {
                            !formData.input_type ? 
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.9834 10C12.9834 11.65 11.6501 12.9833 10.0001 12.9833C8.35006 12.9833 7.01672 11.65 7.01672 10C7.01672 8.35 8.35006 7.01667 10.0001 7.01667C11.6501 7.01667 12.9834 8.35 12.9834 10Z" stroke="#201D1D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M9.99999 16.8917C12.9417 16.8917 15.6833 15.1583 17.5917 12.1583C18.3417 10.9833 18.3417 9.00833 17.5917 7.83333C15.6833 4.83333 12.9417 3.1 9.99999 3.1C7.05833 3.1 4.31666 4.83333 2.40833 7.83333C1.65833 9.00833 1.65833 10.9833 2.40833 12.1583C4.31666 15.1583 7.05833 16.8917 9.99999 16.8917Z" stroke="#201D1D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg> 
                        :
                            <img src={hideIcon} width="20" height="20" style={{ cursor : 'pointer' }} />
                        }
                        </span>
                        {formData.error && formData.error.password && <span className="error-msg">{formData.error.password}</span>}
                    </div>
                    <div className="form-control" style={{ borderRadius: "100px", marginTop: "4%" }}>

                        <label htmlFor="image" className="form-label" style={{ background: "#ffffff", left: 'unset' }} >
                            <input type="file" name="image" id='image' className="form-input" onChange={handleFileInput} style={{ border: '1.5px solid #e3e3e3', display: 'none' }} accept="image/*" />
                            <Button  
                                className="btn-choose"
                                variant="outlined"
                                component="span" >
                                Choose Image
                            </Button>
                            {
                                imagePreview ?
                                    (
                                        <img src={imagePreview} alt="Preview" style={{ maxWidth: '20%', borderRadius: '5%', paddingLeft: '5%' }} />
                                    )
                                    :
                                    <img src={formData.image} style={{ width: '20%', borderRadius: '5%', paddingLeft: '5%' }} />
                            }

                            {/* &nbsp; {selectedFile ? selectedFile?.name : null} */}
                        </label>
                        {formData.error && formData.error.image && <span className="error-msg">{formData.error.image}</span>}
                        {errorMessage && <span id="email_err" className="error-msg" style={{ color: 'red' }}>{errorMessage}</span>}
                    </div>
                </form>

            </DialogContent>
            <DialogActions style={{ borderTop: "unset", gap: "35%", paddingBottom: "30px" }}>
                {
                    editUserID ?
                        (
                            <button style={{ padding: '13px 24px', width: '100px', height: '50px', background: '#BC1E2D', borderRadius: '100px', border: 'unset', color: 'white', cursor: "pointer", }} onClick={() => submitForm(selectedFile)}>Update</button>
                        )
                        :
                        <button style={{ padding: '13px 24px', width: '100px', height: '50px', background: '#BC1E2D', borderRadius: '100px', border: 'unset', color: 'white', cursor: "pointer", }} onClick={() => submitForm(selectedFile)}>Create</button>
                }
            </DialogActions>
        </BootstrapDialog >

    );
}
export default AddUserModal