import buildingImage from "../../assets/images/home-img.png"
import incomingImage from "../../assets/images/incoming.png"
import outgoingImage from "../../assets/images/outgoing.png"
import calendarImage from "../../assets/images/calendar-icon.png"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useRef, useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";
import { TableCell } from "@mui/material";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function Delivery({ deliveryCount, logs, incomingData, outgoingData, escortsData, labels, tenantActivityLogs, standradActivityLogs,escortActivityLogs, customPicker,customYear,customMonth,setCustomMonth,setCustomYear,datePickerLable,incomingCount,outgoingCount,escortCount }) {
    const ref = useRef(null);
    const itemsPerPage = 10;
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const [logData, setLogData] = useState([]);
    const [pageCount, setPageCount] = useState();
    const [allLogs, setAllLogs] = useState();
    const [search, setSearch] = useState();
    const standardReport = useRef(null);
    const tenantReport = useRef(null);
    const escortReport = useRef(null);
    const [downloadReportPanel, setDownloadReportPanel] = useState(false);
    const downloadReport = useRef(null)
    const quarters = [];
    const today = new Date();
    const [year, setYear] = useState(new Date().getFullYear());
    
    const [yearLable, setYearLable] = useState();

    const handlePrevYear = () => {
        setYear(year - 1);
    };

    const handleNextYear = () => {
        setYear(year + 1);
    };

    let presetOptions = {};
    presetOptions = {
        'Current Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
        'Current Year': [moment().startOf('year'), moment().endOf('year')],
        'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
    }

    const pushData = (object, key, value) => {
        object[key] = value;
    };

    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            function handleClickOutside(event) {
                console.log("target",event.target)
                if (ref.current && !ref.current.contains(event.target)) {

                    if (event?.target?.id !== 'downloadReport') {
                        setDownloadReportPanel(false)
                    }
                    if (event?.target?.id !== 'customPicker' && event?.target?.className !='datePickerDropdownSubMenu' && event?.target?.className !='previcon'  
                    && event?.target?.tagName !='svg' && event?.target?.tagName !='path' && event?.target?.className!= 'dropdown-item'
                    ) {
                        setTimeout(() => {
                            setCustomMonth(false)
                            setCustomYear(false)
                        }, 200);
                    }
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideAlerter(downloadReport);


    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    for (let i = 0; i < 5; i++) {
        const currentQuarter = Math.floor(today.getMonth() / 3) + 1;
        const currentYear = today.getFullYear();
        const quarterStartDate = new Date(currentYear, (currentQuarter - 1) * 3, 1);
        const quarterEndDate = new Date(currentYear, currentQuarter * 3, 0);
        //quarterString = 'Q'+currentQuarter+'-'+currentYear;
        // const quarter = { 
        //     quarterString : [formatDate(quarterStartDate),formatDate(quarterEndDate)]
        // }
        quarters.push('Q' + currentQuarter + '-' + currentYear);
        pushData(presetOptions, 'Q' + currentQuarter + '-' + currentYear, [formatDate(quarterStartDate), formatDate(quarterEndDate)])
        today.setMonth(today.getMonth() - 3);
    }
    console.log("presets", presetOptions);



    useEffect(() => {
        console.log("assssd", logs)
        console.log(itemOffset, endOffset)
        const currentItems = logs?.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(logs?.length / itemsPerPage);
        console.log("currentItems", currentItems)
        setPageCount(pageCount);
        setLogData(currentItems);
        setAllLogs(logs);


    }, [logs])





    const serachLog = (e) => {
        setSearch(e.target.value)
        if (e.target.value) {
            const filtered = allLogs.filter(entry => Object.values(entry).some(val => val?.toString().toLowerCase().trim().includes(e.target.value.toLowerCase().trim())));
            setLogData(filtered)
            const currentItems = filtered?.slice(itemOffset, endOffset);
            const pageCount = Math.ceil(filtered?.length / itemsPerPage);
            setPageCount(pageCount);
        } else {
            const currentItems = logs?.slice(0, endOffset);
            const pageCount = Math.ceil(logs?.length / itemsPerPage);
            setPageCount(pageCount);
            setLogData(currentItems)
        }

    }


    const options = {
        plugins: {
            title: {
                display: true,
                text: '',
            },
            legend: {
                position: "top",
                align: "end"
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        }
    };

    console.log('labels:', labels)
    const data = {
        labels,
        datasets: [
            {
                label: 'Incoming',
                data: incomingData?.length > 0 && incomingData,
                backgroundColor: 'rgb(133, 110, 206)',
            },
            {
                label: 'Outgoing',
                data: outgoingData?.length > 0 && outgoingData,
                backgroundColor: 'rgb(96, 166, 90)',
            },
            {
                label: 'Escorts',
                data: escortsData?.length > 0 && escortsData,
                backgroundColor: 'rgb(188, 30, 45)',
            },
        ],
    };



    const handlePageClick = (event) => {
        console.log('logs', allLogs);
        const newOffset = (event.selected * itemsPerPage) % allLogs.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
        const endOffset = newOffset + itemsPerPage;
        console.log("asassa", newOffset, endOffset)
        let currentItems = allLogs?.slice(newOffset, endOffset);
        console.log(currentItems)
        setLogData(currentItems);
    };

    const exportCSV = () => {
        setDownloadReportPanel(!downloadReportPanel);
    }


    const csvDownload = (id) => {
        if (id === 1) {
            standardReport.current.link.click();
        } else if (id === 2) {
            tenantReport.current.link.click();
        } else if (id === 3) {
            escortReport.current.link.click();
        }
        setDownloadReportPanel(false);
    }

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const [startYear, setStartYear] = useState(new Date().getFullYear());
    

    useEffect(() => {
        let sYear =  startYear
        let eYear =  startYear + 11    
        setYearLable(sYear+'-'+eYear)
    }, [])
    
    const handlePrevDecade = () => {
        let prev = startYear - 12
        setYearLable(prev+'-'+(startYear-1))
        setStartYear(prev)
    };

    const handleNextDecade = () => {
        let next = startYear + 12
        setYearLable(next+'-'+(next+11))
        setStartYear(next)
    };

    const renderYears = () => {
        const years = [];
        for (let i = startYear; i < startYear + 12; i++) {
            if(i <= new Date().getFullYear() ) {
                years.push(<div onClick={() => customPicker(6,i)} className="grid-item" key={i}>{i}</div>);
            } else {
                years.push(<div className="grid-item" style={{ color:'grey' }} key={i}>{i}</div>);
            }
        }
        return years;
    };

    return (
        <div className="column-main-wrapper">
            <div className="report-section">
                <div className="report-items">
                    <div className="report-item">
                        <div className="report-item-box incoming-box">
                            <div className="report-item-left">
                                <span className="report-name">Incoming</span>
                                <strong className="report-number">
                                    {incomingCount}
                                </strong>
                            </div>
                            <div className="report-item-right">
                                <span className="report-icon" style={{ background: 'unset' }}>
                                    <img src={incomingImage} />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="report-item">
                        <div className="report-item-box outgoing-box">
                            <div className="report-item-left">
                                <span className="report-name">Outgoing</span>
                                <strong className="report-number">
                                    {outgoingCount}
                                </strong>
                            </div>
                            <div className="report-item-right">
                                <span className="report-icon" style={{ background: 'unset' }}>
                                    <img src={outgoingImage} />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="report-item">
                        <div className="report-item-box escorts-box">
                            <div className="report-item-left">
                                <span className="report-name">Escorts</span>
                                <strong className="report-number">
                                    {escortCount}
                                </strong>
                            </div>
                            <div className="report-item-right">
                                <span className="report-icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M16 3.13C16.8604 3.35031 17.623 3.85071 18.1676 4.55232C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89318 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="column-main-container graph-section">
                <div className="column-topbar-section">
                    <div className="column-main-title">
                        {/* <h2 className="column-title">Deliveries</h2> */}
                        <div className="datePickerDropdownBlock">
                            <div className="dropdown">
                                <a className="datePickerDropdownToggle dropdown-toggle" role="button" id="datePickerDropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
                                    {datePickerLable} <img src={calendarImage} />
                                </a>
                                <ul className="datePickerDropdownMenu dropdown-menu" id="customPicker" aria-labelledby="datePickerDropdownMenu" onClick={(event) => event.stopPropagation()}>
                                    <li>
                                        <a className="dropdown-item">Quarterly</a>
                                        <div className="datePickerDropdownSubMenuBlock">
                                            <ul className="datePickerDropdownSubMenu">
                                                {
                                                    quarters.map(function (quarterValue, key) {
                                                        return <li onClick={() => customPicker(5, presetOptions[quarterValue],quarterValue)} data-id={key}><a className="dropdown-item">{quarterValue}</a></li>
                                                    })
                                                }
                                            </ul>
                                        </div>  
                                    </li>
                                    <li>
                                        <a className="dropdown-item">Monthly</a>
                                        <div className={"datePickerDropdownSubMenuBlock "+(customMonth?'customDatePicker':'')}>
                                            <ul className="datePickerDropdownSubMenu">
                                                {
                                                    <>
                                                        <div className="customDatePickerList" style={{ display : customMonth ? 'block' : 'none' }} >
                                                            <div className="customDatePickerHead">
                                                                <span className="datePickerIcon">
                                                                    <svg className="previcon" onClick={handlePrevYear} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 256 512">
                                                                        <path className="previcon" d="M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z" />
                                                                    </svg>
                                                                </span>
                                                                <span className="datePickerTitle">{year}</span>
                                                                <span className="datePickerIcon">
                                                                    <svg className="previcon" onClick={handleNextYear} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 256 512">
                                                                        <path className="previcon" d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                                                                    </svg>
                                                                </span>
                                                            </div>
                                                            <div className="grid-container customDatePickerItems">
                                                                {
                                                                    months.map(function (monthName) {
                                                                        return <div onClick={() => customPicker(7,monthName,year)} className="grid-item">{monthName}</div>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="datePickerDropdownSubMenuList" style={{ display : !customMonth ? 'block' : 'none' }}>
                                                            <li onClick={() => customPicker(1)}><a className="dropdown-item">This Month</a></li>
                                                            <li onClick={() => customPicker(2)}><a className="dropdown-item">Last Month</a></li>
                                                            <li onClick={() => setCustomMonth(!customMonth)}><a className="dropdown-item">Custom Month</a></li>
                                                        </div>
                                                    </>    
                                                }
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <a className="dropdown-item">Yearly</a>
                                        <div className="datePickerDropdownSubMenuBlock">
                                            <ul className={"datePickerDropdownSubMenu "+(customYear?'customDatePicker':'')}>
                                                {
                                                    customYear ?
                                                        <div className="customDatePickerList">
                                                            <div className="customDatePickerHead">
                                                                <span className="datePickerIcon">
                                                                    <svg className="previcon" onClick={handlePrevDecade} style={{ cursor: 'pointer', textAlign: 'left' }} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 256 512">
                                                                        <path className="previcon" d="M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z" />
                                                                    </svg>
                                                                </span>
                                                                <span className="datePickerTitle">{yearLable}</span>
                                                                <span className="datePickerIcon">
                                                                    <svg className="previcon" onClick={handleNextDecade} style={{ cursor: 'pointer', textAlign: 'right' }} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 256 512">
                                                                        <path className="previcon" d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                                                                    </svg>
                                                                </span>
                                                            </div>
                                                            <div className="grid-container customDatePickerItems">
                                                                {renderYears()}
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="datePickerDropdownSubMenuList">
                                                            <li onClick={() => customPicker(3)}><a className="dropdown-item">This Year</a></li>
                                                            <li onClick={() => customPicker(4)}><a className="dropdown-item">Last Year</a></li>
                                                            <li onClick={() => setCustomYear(!customYear)}><a className="dropdown-item">Custom Year</a></li>
                                                        </div>
                                                }
                                            </ul>

                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="column-topbar-right">

                        <div className="topbar-col monthly-col" id="downloadReport" ref={downloadReport}>
                            <div className="form-action" style={{ margin: 'unset' }}>
                                <TableCell className='action-cell' align="right" style={{ borderBottom: 'unset' }}>
                                    <button type="button" className="form-btn sign-btn" onClick={exportCSV} style={{ cursor: "pointer", height: '10%' }} >Download Report</button>
                                    <div className='action-main-div' style={downloadReportPanel ? { display: 'block' } : { display: 'none' }}>
                                        <ul className='action-main-ul'>
                                            <li style={{ cursor: "pointer", color: "#856ece", background: "#f9f8fd", borderRadius: "20px", margin: "10px 0px" }} onClick={() => csvDownload(1)} ><CloudDownloadIcon style={{ marginRight: "5px", width: "15px" }} />Download Standard Activity Report</li>
                                            <li style={{ cursor: "pointer", color: "#5a9d55", background: "#f7fbf7", borderRadius: "20px" }} onClick={() => csvDownload(2)} ><CloudDownloadIcon style={{ marginRight: "5px", width: "15px" }} />Download Tenant Volume Report</li>
                                            <li style={{ cursor: "pointer", color: "#5a9d55", background: "#f7fbf7", borderRadius: "20px" }} onClick={() => csvDownload(3)} ><CloudDownloadIcon style={{ marginRight: "5px", width: "15px" }} />Download Escort Activity Report
</li>
                                        </ul>
                                    </div>
                                </TableCell>
                            </div>
                            {console.log('standradActivityLogs:', standradActivityLogs)}
                            <CSVLink data={standradActivityLogs} filename={"Standard Activity Report.csv"} ref={standardReport} ></CSVLink>
                            <CSVLink data={tenantActivityLogs} filename={"Tenant Voulume Report.csv"} ref={tenantReport} ></CSVLink>
                            <CSVLink data={escortActivityLogs} filename={"Escort Activity Report.csv"} ref={escortReport} ></CSVLink>
                        </div>

                        

                        {/* <RangePicker
                            format="YYYY-MM-DD"
                            ranges={presetOptions}
                            onChange= {getDateRanges}
                            disabled={[false, false]}
                            open={isOpen}
                            picker="month"
                            allowClear={false} 
                            onClick={handleCalendarClick}
                            onPanelChange={handlePanelChange}
                            defaultValue={[dayjs(startDate, "YYYY-MM-DD"), dayjs(endDate, "YYYY-MM-DD")]}
                            value={[dayjs(startDate, "YYYY-MM-DD"), dayjs(endDate, "YYYY-MM-DD")]}
                        /> */}


                    </div>
                </div>
                <div className="column-list-section">
                    <Bar options={options} data={data} />
                </div>
            </div>

            <div className="card-section tenant-activity-sect">
                <div className="card-head">
                    <h2 className="card-title">Tenant’s Activity</h2>
                    <div className="search-section">
                        <form>
                            <div className="input-group">
                                <input id="search" className="input-field" type="text" placeholder="Search here..." name="Search" value={search} onChange={serachLog} />
                                <button className="btn search-icon">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.33268 15.0003C9.81183 15 11.2484 14.5049 12.4135 13.5937L16.0768 17.257L17.2552 16.0787L13.5919 12.4153C14.5035 11.25 14.999 9.81319 14.9994 8.33366C14.9994 4.65783 12.0085 1.66699 8.33268 1.66699C4.65685 1.66699 1.66602 4.65783 1.66602 8.33366C1.66602 12.0095 4.65685 15.0003 8.33268 15.0003ZM8.33268 3.33366C11.0902 3.33366 13.3327 5.57616 13.3327 8.33366C13.3327 11.0912 11.0902 13.3337 8.33268 13.3337C5.57518 13.3337 3.33268 11.0912 3.33268 8.33366C3.33268 5.57616 5.57518 3.33366 8.33268 3.33366Z" fill="#201D1D" />
                                    </svg>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="card-body">
                    <table className="table">
                        <tbody>
                            {
                                logData?.length > 0 ?
                                    logData?.map(function (activity, i) {
                                        return (<tr key={i}>
                                            <td width="15%">
                                                {
                                                    activity?.picture ?
                                                        <img src={activity?.picture} alt="" style={{ width: "20%", borderRadius: '5px', height: '36px' }} />
                                                        :
                                                        <img src={buildingImage} alt="" style={{ width: "20%", borderRadius: '5px', height: '36px' }} />
                                                }
                                            </td>
                                            <td width="20%">
                                                <div className="academy-box-name">
                                                    {
                                                        activity?.type == '' ?
                                                            activity?.reason :
                                                            activity?.type
                                                    }
                                                </div>
                                            </td>
                                            <td width="20%">
                                                <div className="tenant-address">
                                                    {
                                                        activity?.tenant
                                                    }
                                                </div>
                                            </td>
                                            <td width="20%">
                                                <div className="tenant-address">
                                                    {
                                                        activity?.tenantContact
                                                    }
                                                </div>
                                            </td>
                                            <td width="10%">
                                                <div className="academy-box-name">
                                                    {

                                                        activity?.status == 'None' ?
                                                            <span style={{ color: "#22B0FC" }}>{activity?.status}</span>
                                                            : activity?.status == 'Logged' ?
                                                                <span style={{ color: "#22B0FC" }}>{activity?.status}</span>
                                                                : activity?.status == 'Assigned' ?
                                                                    <span style={{ color: "#FD9E4E" }}>{activity?.status}</span>
                                                                    : activity?.status == 'Delivered' ?
                                                                        <span style={{ color: "#39C876" }}>{activity?.status}</span>
                                                                        : activity?.status == 'Lost' ?
                                                                            <span style={{ color: "#FC5559" }}>{activity?.status}</span>
                                                                            :
                                                                            <span style={{ color: "#22B0FC" }}>{activity?.status}</span>
                                                    }
                                                </div>
                                            </td>
                                            <td className="text-right" width="10%">
                                                <div className="tenant-time">
                                                    {
                                                        activity?.time
                                                    }
                                                </div>
                                            </td>
                                        </tr>)
                                    })
                                    :
                                    <tr>
                                        <td>No activity found</td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                    {
                        logData?.length > 0 ?
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Next"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel="Previous"
                                renderOnZeroPageCount={null}
                                className='pagination'
                            />
                            :
                            ''
                    }
                </div>
            </div>

        </div>
    )
}

export default Delivery;