import List from "./List";

function User({ userList, onClickAddUser, getUserList, deleteAdminUser,resendPasswordLink,totalRecords }) {

    console.log("123",totalRecords);
    return (
        <div className="column-main-wrapper">
            <div className="column-main-container" style={{ paddingBottom: "25px" }}>
                <div className="column-topbar-section">
                    <div className="column-main-title">
                        <h2 className="column-title">Property Managers</h2>
                    </div>
                    <div className="column-topbar-right">
                        <button
                            style={{
                                padding: '13px 24px',
                                width: '200px',
                                height: '50px',
                                background: '#BC1E2D',
                                borderRadius: '100px',
                                border: 'unset',
                                color: 'white',
                                cursor: "pointer"
                            }}

                            onClick={onClickAddUser}

                        >Add Property Manager</button> 

                    </div>
                </div>
                <div className="column-list-section">
                    <List userList={userList} getUserList={getUserList} deleteAdminUser={deleteAdminUser} resendPasswordLink={resendPasswordLink} totalRecords={totalRecords}  />
                </div>


            </div >
        </div >
    )
}
export default User