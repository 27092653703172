import React from 'react';
import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
  setToken,
  setLoading,
  setUserDetails,
  setSelectedBuildingId
} from "../../redux/user/user.action";
import ApiCall from "../../Api/index"
import { useParams } from 'react-router';

function Index() {

  const ref = useRef(null);
  const dispatch = useDispatch();
  const [token, setToken] = useState("");
  const { id, month } = useParams();
  
  const [iframeUrl, setIframeUrl] = useState("");
  const buildingID = useSelector((state) => state?.user?.selectedBuildingId);
  const buildingName = useSelector((state) => state?.user?.selectedBuildingName);
  const [randomKey,setRandomKey] = useState(buildingID);

  useEffect(() => {
    dispatch(setSelectedBuildingId(id))
    console.log("buildingIDTest", buildingID)
    setRandomKey(buildingID)
    console.log("buildingData", buildingName)
    getToken()
  }, [buildingID]);

 

  const getToken = async () => {
    dispatch(setLoading(true))
    const jwtToken = await ApiCall('generate-token', 'get', {});
    dispatch(setLoading(false))
    let url = jwtToken?.data?.METABASE_URL+"?building=" + buildingName + "#hide_parameters=building";
    setIframeUrl(url)
  }

  return (
    <div className="App">
      <div>
        <iframe
          src={iframeUrl}
          frameborder="0"
          width="100%"
          key={buildingID}
          style={{ overflow: "hidden", height: "100vh", width: "100%" }}
          allowtransparency
        ></iframe>
      </div>

    </div>
  );
}

export default Index;