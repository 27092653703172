export default function listenForOutsideClicks(
  listening,
  setListening,
  menuRef,
  setIsOpen,
) {
  return () => {
    if (listening) return
    if (!menuRef.current) return
    setListening(true)
    ;[`click`, `touchstart`].forEach((type) => {
      document.addEventListener(`click`, (evt) => {
        console.log("event123",evt)
        const cur = menuRef?.current
        const node = evt?.target
        console.log("node",node);
        console.log("current",cur);
        //if(node.className == 'search') return
        if (cur?.contains(node)) return
        setIsOpen(false)
      })
    })
  }
}