import { Link } from "react-router-dom"
// import Logo from "../../components/Logo"
import WavingHand from "../assets/images/waving-hand.png"
import stateBuildingImage from "../assets/images/state-building.png"
import arrowDownImage from "../assets/images/arrow-down.svg"
import arrowUpImage from "../assets/images/arrow-up.png"
import userImage from "../assets/images/profile.png"
import vectorImage from "../assets/images/vector.png"
import { useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import LogoImg from "../assets/images/supreme_point.png"
import { setLogout, setSelectedBuildingId,setSelectedBuildingName } from "../redux/user/user.action"
import { confirmAlert } from 'react-confirm-alert';
import listenForOutsideClick from './OutsideClick'
import React, { useEffect, useRef } from 'react'
import ApiCall from "../Api/index"
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from 'react-router';

function Header() {
    const { id } = useParams();

    const perms = useParams()
    const dispatch = useDispatch()
    const userName = useSelector((state) => state?.user?.userDeatils?.name);
    const navigate = useNavigate()
    const [profileState, setProfileState] = useState(false);
    const [showLogoutDropdown, setIsOpen] = useState(false);
    const [arrowImage, setArrowImage] = useState(arrowDownImage);
    const showHideProfileDropdown = () => {
        setProfileState(!profileState)
        if(profileState) {
            setArrowImage(arrowDownImage)
        } else {
            setArrowImage(arrowUpImage)
        }
        
    }
    const menuRef = useRef(null)
    const locationRef = useRef(null)
    const [listening, setListening] = useState(false)
    useEffect(listenForOutsideClick(listening, setListening, menuRef, setIsOpen))
    const [building, setBuildingList] = useState([]);
    const [allBuilding, setAllBuildingList] = useState([]);
    const [search, setSearch] = useState();
    const token = useSelector((state) => state.user.userToken);
    const [buildingName, setBuildingName] = useState("Select Building");
    const location = useLocation();
    const [currentLocation, setCurrentLocation] = useState();
    const [buildingImage, setBuildingImage] = useState(stateBuildingImage);
    const [dayType,setDayType] = useState('');
    useOutsideAlerter(locationRef); 

    //alert(id)


    const showSettings = () => {
        setIsOpen(!showLogoutDropdown);
    } 

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    
                    console.log("target_id",event?.target?.id)
                    if (event?.target?.id !== 'buildingSpecific') {
                        setProfileState(false)
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useEffect(() => {
        console.log(location.pathname);
        if (location.pathname == '/') {
            navigate('/home');
        }
        setCurrentLocation(location.pathname);
    }, [location])

    const logout = () => {
        confirmAlert({
            title: 'Confirm to logout ?',
            message: 'Are you sure you want to log out?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(setLogout())
                        navigate('/home'); 
                    }
                },
                {
                    label: 'No',
                    onClick: () => {

                    }
                }
            ]
        });
    }

    useEffect(() => {
        (async () => {
            var today = new Date()
            var curHr = today.getHours()

            if (curHr < 12) {
                setDayType('Good Morning');
            } else if (curHr < 18) {
                setDayType('Good Afternoon');
            } else {
                setDayType('Good Evening');
            }

            const buildingList = await ApiCall('v1/manage-building-web', 'get', null, token);
            if (buildingList?.data) {
                setBuildingList(buildingList?.data?.RESULT);
                setAllBuildingList(buildingList?.data?.RESULT)

            } else {
                if (buildingList.error == 'Unauthorized') {
                    dispatch(setLogout())
                }
                setBuildingList([])
            }
        })()
    }, [])


    useEffect(() => {
        const name = allBuilding?.find((e) => Number(e.id) == Number(location.pathname.split('/')[2]))?.name
        const buildingImage = allBuilding?.find((e) => Number(e.id) == Number(location.pathname.split('/')[2]))?.building_image
        setBuildingName(name)
        dispatch(setSelectedBuildingName(name))
        setBuildingImage(buildingImage);
    }, [id, allBuilding, location.pathname])


    const gotoBuildingDetail = (buildingID, name, buildingImage) => {

        dispatch(setSelectedBuildingId(buildingID))
        dispatch(setSelectedBuildingName(name))
        let newDate = new Date()
        let monthID = newDate.getMonth() + 1;
        setBuildingName(name)
        setBuildingImage(buildingImage);
        navigate('metabase/' + buildingID + '/' + monthID);
        setProfileState(false);
    }

    const searchBuilding = async (e) => {
        setSearch(e.target.value)
        console.log(e.target.value);

        if (e.target.value && e.target.value != '') {
            let data = building?.map((e) => {
                return {
                    id: e.id,
                    name: e.name,
                    full_address: e.full_address,
                    building_image: e.building_image,
                    zip: e.zip
                }
            })
            // console.log(data)
            const filtered = data.filter(entry => Object.values(entry).some(val => val?.toString()?.toLowerCase().trim().includes(e.target.value.toLowerCase().trim())));
            setBuildingList(filtered)
        } else {

            setBuildingList(allBuilding);
        }
    }




    return (
        <header className="page-header">
            <div className="header-left">
                <div className="header-menu desktop-hide">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" /></svg>
                </div>
                <h1 className="header-welcome">
                    {dayType}, {userName} 👋🏻 
                    {/* <img src={WavingHand} alt="waving-hand" width="20" height="20" /> */}
                </h1>
            </div>
            <div className="header-right">
                {   
                    currentLocation?.includes('deliver') || currentLocation?.includes('user') || currentLocation?.includes('setting') || currentLocation?.includes('metabase') ?
                        <div className="state-column" id="buildingSpecific">
                            <div className="state-dropdown"  onClick={showHideProfileDropdown} ref={locationRef} id="buildingSpecific">
                                <div className="state-dropdown-icon" id="buildingSpecific">
                                    <img src={buildingImage} alt="Building" width="34" height="34" id="buildingSpecific" />
                                </div>
                                <div className="state-dropdown-text" id="buildingSpecific">
                                    <span className="state-name buildingName selectedBuilding" id="buildingSpecific">{buildingName}</span>
                                </div>
                                <div className="state-dropdown-arrow" id="buildingSpecific">
                                    <img src={arrowImage} alt="Arrow" width="16" height="16" id="buildingSpecific" />
                                </div>
                            </div>

                            <div className={profileState ? " dropdown-active state-dropdown-list" : "state-dropdown-list"} style={{ width: '150%' }} ref={locationRef}>
                                <div className="seach_bar">
                                    <img src={vectorImage} alt="" className="search_icon" />
                                    <input type="search" className="search" placeholder="Search here..." onChange={searchBuilding} />
                                </div>
                                <ul className="state-dropdown-items building-dropdown" >
                                    {
                                        building?.length > 0 ?
                                            building?.map(e => {
                                                return (
                                                    <li className="state-dropdown-item" onClick={() => gotoBuildingDetail(e.id, e.name, e?.building_image)} >
                                                        <div className="drop_box">
                                                            <span className="image_box" href="javascript:void(0)" onClick={() => gotoBuildingDetail(e.id, e.name, e?.building_image)}>
                                                                <img src={e?.building_image != null && e?.building_image} alt="img" className="image" />
                                                            </span>
                                                            <div className="country_info" onClick={() => gotoBuildingDetail(e.id, e.name, e?.building_image)}>
                                                                <a className="cnt_name" id={"building-"+e.id} href="javascript:void(0)">{e.name}</a>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                            :
                                            <span style={{ textAlign : 'center' }}>No result found</span>
                                    }
                                </ul>
                            </div>
                        </div>
                        : ''
                }
                <div className="header-user logoutDropdown">
                    <span className="header-user-img logoutDropdown">
                        <img src={userImage} alt="User" onClick={showSettings} style={{ cursor: 'pointer' }} className="logoutDropdown" ref={menuRef} />
                    </span>
                    <div className={showLogoutDropdown ? " dropdown-active state-dropdown-list settings-dropdown-list logoutDropdown" : "state-dropdown-list settings-dropdown-list logoutDropdown"} >
                        <ul className="state-dropdown-items logoutDropdown">
                            <li className="state-dropdown-item logoutDropdown">
                                <a href="#" className="logoutDropdown">Settings</a>
                            </li>
                            <li className="state-dropdown-item logoutDropdown" onClick={logout}>
                                <a href="#" className="logoutDropdown">Logout</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}
export default Header