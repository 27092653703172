function NotAuthorized() {
    


    return (
        <div className="column-main-wrapper">
            <div className="report-section">
                <div className="report-items">
                    You are not authorized
                </div>
            </div>

        </div>
    )
}

export default NotAuthorized;