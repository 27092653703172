import React, { useState,useEffect } from "react";
import SortDown from "../../assets/images/caret-down-solid.svg"
import SortUp from "../../assets/images/caret-up-solid.svg"
import Ban from "../../assets/images/ban-solid.svg"
import Options from "../../assets/images/ellipsis-solid.svg"
import EmailPng from "../../assets/images/Message.png"
import ArchivePng from "../../assets/images/archive_new.png"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { TableCell } from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import { useRef } from "react";



const List = ({ userList, getUserList, deleteAdminUser, resendPasswordLink, totalRecords }) => {

    const [showAction, setShowAction] = useState('')
    const userOptions = useRef(null)
    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            function handleClickOutside(event) {
                console.log("target",event.target)
                if (ref.current && !ref.current.contains(event.target)) {

                    if (event?.target?.id !== 'usersOptions') {
                        setShowAction(false)
                    }
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideAlerter(userOptions);

    async function onChangePagination(e, page) {
        await getUserList(page);
    }

    const closePopup = () => {
        setShowAction(false)
    }

    console.log("totalRecords", totalRecords);

    return (
        <>

            <div className="table-responsive">
                <table className="cus-data-table">
                    <thead>
                        <tr>
                            <td>Name</td>
                            <td>Email Address </td>
                            {/* <td>Role </td> */}
                            <td></td>
                            <td>Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            userList.length > 0 ?
                                userList.map((user, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{user.name}</td>
                                            <td>
                                                {
                                                    user.email ?
                                                        <>
                                                            <img style={{ width: '16px', height: '14px' }} src={EmailPng} />
                                                            &nbsp;&nbsp;{user.email}</>
                                                        :
                                                        '-'
                                                }
                                            </td>

                                            <td style={{ color: "#88352D", cursor: "pointer", width: "8%" }} onClick={() => deleteAdminUser(user.id)} >
                                                <img style={{ width: '20px', height: '25px' }} src={ArchivePng} />
                                                &nbsp;<span style={{ color: "#F2CA00" }}> Archive</span>
                                            </td> 

                                            <TableCell id="usersOptions" ref={userOptions} className='action-cell' align="right">
                                                <svg id="usersOptions" style={showAction === user?.id ? { cursor: "pointer", fill: '#88352d' } : { cursor: "pointer" }} onClick={() => setShowAction(showAction != '' && user?.id === showAction ? '' : user?.id)} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                                                    <path id="usersOptions" d="M0 0h24v24H0z" fill="none" /><path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                                                </svg>
                                                <div className='action-main-div' id="usersOptions" style={showAction === user?.id ? {} : { display: "none" }}>
                                                    <ul className='action-main-ul' id="usersOptions">
                                                        {/* <li style={{ cursor: "pointer", color: "#856ece", background: "#f9f8fd", borderRadius: "20px", margin: "10px 0px" }}><BorderColorIcon style={{ marginRight: "5px", width: "15px" }} />Assign Admin Role</li> */}
                                                        <li id="usersOptions" onClick={ function() { resendPasswordLink(user.email);closePopup() } } style={{ cursor: "pointer", color: "#5a9d55", background: "#f7fbf7", borderRadius: "20px" }}><SyncLockIcon id="usersOptions" style={{ marginRight: "5px", width: "15px" }} />Reset Password Email</li>
                                                    </ul>

                                                </div>
                                            </TableCell>
                                            {/* </td> */}
                                        </tr>
                                    )

                                }) :
                                <tr>
                                    <td colSpan="6" style={{ textAlign: "center" }}>No Records to display</td>
                                </tr>
                        }

                    </tbody>
                </table>
            </div>
            {
                totalRecords > 0 ?
                    <Stack spacing={2} justifyContent="end" direction="row">
                        <Pagination count={totalRecords} color="secondary" onChange={async (event, page) => await onChangePagination(event, page)} />
                    </Stack>
                    :
                    ''
            }

        </>
    )

}
export default List